.above-all {
  padding: 50px 40px;
}
.above-all__row {
  display: flex;
  justify-content: space-around;
  gap: 40px;
}
.above-all__column {
  text-align: center;
  max-width: 280px;
}
.above-all__cell {
  max-width: 320px;
  text-align: center;
}
.above-all__text {
  font-size: 18px;
  line-height: 22px;

  &.blue {
    color: $blue;
  }
  &.green {
    color: $green;
    font-size: 42px;
  }
  &.small {
    font-size: 22px;
  }
}
.above-all__img--center {
  margin-bottom: 3rem;
  transition:all .2s ease-in-out;
  -webkit-transition:all .2s ease-in-out;
  -moz-transition:all .2s ease-in-out;
  -ms-transition:all .2s ease-in-out;
  -o-transition:all .2s ease-in-out;

  &:hover {
    transform:scale(1.1);
    -webkit-transform:scale(1.1);
    -moz-transform:scale(1.1);
    -ms-transform:scale(1.1);
    -o-transform:scale(1.1)
  }
}

@media (max-width: 767px) {
  .above-all {
    padding: 50px 10px;

    .section__title {
      margin-bottom: 30px;
    }
  }
  .above-all__row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 10px 10px;

    &.above-all__row--grid {
      grid-template-rows: 300px 1fr;
    }
  }
  .above-all__text {
    font-size: 13px;
    line-height: 15px;
  }
  .above-all__img {
    width: 36px;

    &.above-all__img--center {
      width: 121px;
      margin-bottom: 0;
    }
  }
  .above-all__cell {
    &.above-all__cell--center {
      grid-column-start: 1;
      grid-column-end: -1;
      grid-row-start: 1;
      grid-row-end: 2;
      margin: 0 auto;

      & div {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
  }
}