
$white:white;
$accentColor:#4caf50;
$headersColor:#212121;

$black:black;

$gradientColor1:#30cfd0;
$gradientColor2:#330867;

$gradientColor1a:#00C2FF;
$gradientColor2a:#0018BF;
$gradientColor3:#061267;
$gradientColor4:#000529;

$footerBG:linear-gradient(89.99deg, #E7EBF7 0.02%, #B0C6DE 74.65%, #B0C6DE 99.99%);
$footerBG-mobil:linear-gradient(to left, $gradientColor3, $gradientColor2a, $gradientColor1a, $gradientColor2a, $gradientColor3);
$priceBG:linear-gradient(to left, $gradientColor1, $gradientColor2);


$bloggerColor: #f57c00;
$fbColor: #3b5998;
$gplusColor: #dd4b39;
$instaColor: #c13584;
$linkedinColor: #0077b5;
$livejournalColor: #004359;
$myworldColor: #168de2;
$okColor: #ed812b;
$pencilColor: #4AC948;
$pinterestColor: #bd081c;
$redditColor: #ff4500;
$rssColor: #f26522;
$telegramColor: #0088cc;
$tiktokColor: #101020;
$tumblrColor: #001935;
$twitterColor: #1da1f2;
$vkColor: #45668e;
$youtubeColor: #cd201f;
.acc-instagram{
  background: #ffdc80;
  background: -moz-linear-gradient(45deg,  #ffdc80 0%, #f77737 22%, #f56040 37%, #fd1d1d 52%, #fd1d1d 52%, #c13584 66%, #c13584 66%, #833ab4 78%, #405de6 100%);
  background: -webkit-linear-gradient(45deg,  #ffdc80 0%,#f77737 22%,#f56040 37%,#fd1d1d 52%,#fd1d1d 52%,#c13584 66%,#c13584 66%,#833ab4 78%,#405de6 100%);
  background: linear-gradient(45deg,  #ffdc80 0%,#f77737 22%,#f56040 37%,#fd1d1d 52%,#fd1d1d 52%,#c13584 66%,#c13584 66%,#833ab4 78%,#405de6 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffdc80', endColorstr='#405de6',GradientType=1 );
}
.acc-blogger{
  background: $bloggerColor !important;
}
.acc-facebook{
  background: $fbColor !important;
}
.acc-google-plus{
  background: $gplusColor !important;
}
.acc-linkedin{
  background: $linkedinColor !important;
}
.acc-livejournal{
  background: $livejournalColor !important;
}
.acc-my-world{
  background: $myworldColor !important;
}
.acc-odnoklassniki{
  background: $okColor !important;
}
.acc-paper-plane{//there's no telegram icon on fontawesome
  background: $telegramColor !important;
}
.acc-pencil{
  background: $pencilColor !important;
}
.acc-pinterest{
  background: $pinterestColor !important;
}
.acc-reddit{
  background: $redditColor !important;
}
.acc-rss{
  background: $rssColor !important;
}
.acc-tiktok{
  background: $tiktokColor !important;
}
.acc-tumblr{
  background: $tumblrColor !important;
}
.acc-twitter{
  background: $twitterColor !important;
}
.acc-vk, .acc-vk-market {
  background: $vkColor !important;
}
.acc-youtube{
  background: $youtubeColor !important;
}

@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?2b14r8');
  src:  url('../fonts/icomoon.eot?2b14r8#iefix') format('embedded-opentype'),
  url('../fonts/icomoon.ttf?2b14r8') format('truetype'),
  url('../fonts/icomoon.woff?2b14r8') format('woff'),
  url('../fonts/icomoon.svg?2b14r8#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}
.fa-livejournal,
.fa-mail-ru,
.fa-my-world,
.fa-vk-market,
.fa-yandex {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fa-livejournal:before {
  content: "\1f300";
}
.fa-mail-ru:before {
  content: "\e901";
}
.fa-my-world:before {
  content: "\e900";
}
.fa-vk-market:before {
  content: "\e904";
}
.fa.fa-yandex:before {
  content: "\e902";
}
.fab.fa-tiktok:before {
  content: "\e07b";
}