.commonNav{
  position: sticky;
  background: $blue;
  width: 100%;
  top:0;
  z-index: 1;
  //display: none;
  a {
    position: relative;
    display:block;
    padding: 1em;
    //border-bottom: 1px solid lightgray;
    text-decoration: none;
    color:white;
    font-weight: lighter;
    font-size: 18px;

    &:before {
      position: absolute;
      content: '';
      left: 0;
      bottom: 0;
      width: 100%;
      height: 2px;
      background: white;
      transition: transform .5s ease;
      transform:  scaleX(0);
    }

    &:hover:before {
      transform:  scaleX(1);
    }

    &:not(:first-child) {display: none;}
  }
  &.visible a{
    display: block;
  }
  .login{
    display: flex !important;
    justify-content: center;
    align-items: center;
    padding: 0;
    p {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 2.5rem;
      width: 8.5rem;
      border-radius: 100px;
      border: 1px solid $white;
    }
  }
}
@media(max-width: 830px){
  .commonNav {
    min-height: 46px;
  }
  .login{
    position: absolute!important;
    top: 0px;
    right: 6px;
    height: 100%;
  }
  .hamburger{
    background: url('../images/burger-icon.svg');
    background-repeat: no-repeat;
    background-position: 15px 14px;
  }
}
@media (min-width: 829px) {
  .commonNav{
    display:flex;
    justify-content: space-around;
    transition: opacity .2s;
    z-index: 1;
    a {
      display:block!important;
    }
    .hamburger{
      display: none!important;
    }
    .login{
      order:10;
    }
  }
  
}