$animationDuration: 2s;
$position-animation:50%;
$blue: #054D90;
$green: #02B509;
$white: #fff;
#header-carousel {
  background: url("../images/circle-gradient.png") no-repeat -58px -200px;
  background-size: auto 150%;
  font-family: "Lato Medium";
}
.banner:hover{
  text-decoration: none;
  cursor: pointer;
}
.banner{
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-wrap: wrap;
  height: 80vh;
  width: 100%;
  @media (max-width: 767px) {
    margin-top: 0px;
    height: auto;
    justify-content: center;
    min-height: 103vh;
    background-size: auto 100%;
  }
  @media (min-width: 768px) and (max-width: 1528px) {
    //display: flex;
    justify-content: center;
    min-height: 103vh;
  }
  @media (min-width: 992px) and (max-width: 1200px) {
    align-items: flex-end;
  }
  .glob{
    position: absolute;
    z-index: 1;
    width: 50vw;
    top: 6%;
    //filter:drop-shadow(0 12px 10px #ffffff);
    //animation-name: pulse;
    //animation-delay: $animationDuration;
    //animation-duration: $animationDuration;
    //animation-iteration-count: infinite;
    //animation-timing-function: linear;
    @keyframes pulse {
      0%{
        filter:brightness(100%) drop-shadow(0 12px 10px #ffffff);
      }
      80%{
        filter:brightness(100%) drop-shadow(0 12px 10px #ffffff);
      }
      90%{
        filter:brightness(150%) drop-shadow(0 12px 50px #ffffff);
      }
      100%{filter:brightness(100%) drop-shadow(0 12px 10px #ffffff);
      }
    }
    @media (min-width: 320px) and (max-width: 767px){
      width: 118px;
      top: 10%;
    }
    @media (min-width: 768px) and (max-width: 1023px){
      width: 25vw;
      top: 10%;
    }
    @media (min-width: 1024px) {
      width: 300px;
      top: 100px;
    }
    @media (min-width: 1024px) and (min-height: 1200px) {
      width: 40vw;
      top: 3.5%;
    }

    img {
      width: 100%;
    }

    p {
      font-size: 21px;
      font-weight: 700;
      text-align: center;
      color: $blue;
      margin-top: 20px
    }
  }

  .mobil-banner, img.mobil-banner {
    width: 100%;
    @media (min-width: 768px) {
      display: none;
    }
  }
  .animation-ss{
    top: $position-animation;
    //left: $position-animation;
    right: $position-animation;
    height: 10px;
    width: 10px;
    position: absolute;
    z-index: 0;
    //animation-delay: 0.3s;
    //animation-name: fly-tw;
    animation-duration: 3.5s;
    animation-iteration-count: infinite;
    animation-timing-function: cubic-bezier(0,0.3,0.8,0.1);

    // ---- левое крыло начало -----------//

    //анимация летающей twitter соцсети//
    &.circle-tw{
      animation-name: fly-tw;
      //animation-delay: 0.3s;
    }
    @keyframes fly-tw {
      from{
        top: $position-animation;
        left: $position-animation;
        height: 10px;
        width: 10px;
        opacity: 1;
      }
      to{
        top: 80%;
        left: -10%;
        height: 60px;
        width: 60px;
        opacity: 0;
      }
    }
    @media (max-width: 767px), (min-width: 768px) and (max-width: 819px) and (max-height: 375px) {
      @keyframes fly-tw {
        from{
          top: $position-animation;
          left: $position-animation;
          height: 0px;
          width: 0px;
          opacity: 1;
        }
        to{
          top: 80%;
          left: -15%;
          height: 40px;
          width: 40px;
          opacity: 0;
        }
      }
    }


    &.circle-tw-2{
      animation-name: fly-tw-2;
      animation-delay: 1.75s;
    }
    @keyframes fly-tw-2 {
      from{
        top: $position-animation;
        left: $position-animation;
        height: 10px;
        width: 10px;
        opacity: 1;
      }
      to{
        top: -10%;
        left: 15%;
        height: 60px;
        width: 60px;
        opacity: 0;
      }
    }
    @media (max-width: 767px), (min-width: 768px) and (max-width: 819px) and (max-height: 375px) {
      @keyframes fly-tw-2 {
        from{
          top: $position-animation;
          left: $position-animation;
          height: 0px;
          width: 0px;
          opacity: 1;
        }
        to{
          top: -10%;
          left: 15%;
          height: 40px;
          width: 40px;
          opacity: 0;
        }
      }
    }
    //анимация летающей OK соцсети//
    &.circle-ok{
      animation-name: fly-ok;
      animation-delay: 0.4s;
    }
    @keyframes fly-ok {
      from{
        top: $position-animation;
        left: $position-animation;
        height: 10px;
        width: 10px;
        opacity: 1;
      }
      to{
        top: 15%;
        left: -10%;
        height: 75px;
        width: 75px;
        opacity: 0;
      }
    }
    @media (max-width: 767px), (min-width: 768px) and (max-width: 819px) and (max-height: 375px) {
      @keyframes fly-ok {
        from{
          top: $position-animation;
          left: $position-animation;
          height: 0px;
          width: 0px;
          opacity: 1;
        }
        to{
          top: 15%;
          left: -15%;
          height: 40px;
          width: 40px;
          opacity: 0;
        }
      }
    }


    &.circle-ok-2{
      animation-name: fly-ok-2;
      animation-delay: 1.5s;
    }
    @keyframes fly-ok-2 {
      from{
        top: $position-animation;
        left: $position-animation;
        height: 10px;
        width: 10px;
        opacity: 1;
      }
      to{
        top: 80%;
        left: -10%;
        height: 75px;
        width: 75px;
        opacity: 0;
      }
    }
    @media (max-width: 767px), (min-width: 768px) and (max-width: 819px) and (max-height: 375px) {
      @keyframes fly-ok-2 {
        from{
          top: $position-animation;
          left: $position-animation;
          height: 0px;
          width: 0px;
          opacity: 1;
        }
        to{
          top: 80%;
          left: -15%;
          height: 40px;
          width: 40px;
          opacity: 0;
        }
      }
    }

    //анимация летающей VK соцсети//
    &.circle-vk{
      animation-name: fly-vk;
      animation-delay: 0.7s;
    }
    @keyframes fly-vk {
      from{
        top: $position-animation;
        left: $position-animation;
        height: 10px;
        width: 10px;
        opacity: 1;
      }
      to{
        top: -10%;
        left: 15%;
        height: 60px;
        width: 60px;
        opacity: 0;
      }
    }
    @media (max-width: 767px), (min-width: 768px) and (max-width: 819px) and (max-height: 375px) {
      @keyframes fly-vk {
        from{
          top: $position-animation;
          left: $position-animation;
          height: 0px;
          width: 0px;
          opacity: 1;
        }
        to{
          top: -10%;
          left: 15%;
          height: 40px;
          width: 40px;
          opacity: 0;
        }
      }
    }

    &.circle-vk-2{
      animation-name: fly-vk-2;
      animation-delay: 2.1s;
    }
    @keyframes fly-vk-2 {
      from{
        top: $position-animation;
        left: $position-animation;
        height: 10px;
        width: 10px;
        opacity: 1;
      }
      to{
        top: 50%;
        left: -10%;
        height: 100px;
        width: 100px;
        opacity: 0;
      }
    }
    @media (max-width: 767px), (min-width: 768px) and (max-width: 819px) and (max-height: 375px) {
      @keyframes fly-vk-2 {
        from{
          top: $position-animation;
          left: $position-animation;
          height: 0px;
          width: 0px;
          opacity: 1;
        }
        to{
          top: 50%;
          left: -15%;
          height: 60px;
          width: 60px;
          opacity: 0;
        }
      }
    }
    //анимация летающей tumblr соцсети///
    &.circle-tumblr{
      animation-name: fly-tumblr;
      animation-delay: 0.9s;
    }
    @keyframes fly-tumblr {
      from{
        top: $position-animation;
        left: $position-animation;
        height: 10px;
        width: 10px;
        opacity: 1;
      }
      to{
        top: 50%;
        left: -10%;
        height: 60px;
        width: 60px;
        opacity: 0;
      }
    }
    @media (max-width: 767px), (min-width: 768px) and (max-width: 819px) and (max-height: 375px) {
      @keyframes fly-tumblr {
        from{
          top: $position-animation;
          left: $position-animation;
          height: 0px;
          width: 0px;
          opacity: 1;
        }
        to{
          top: 50%;
          left: -15%;
          height: 40px;
          width: 40px;
          opacity: 0;
        }
      }
    }


    &.circle-tumblr-2{
      animation-name: fly-tumblr-2;
      animation-delay: 3.1s;
    }
    @keyframes fly-tumblr-2 {
      from{
        top: $position-animation;
        right: $position-animation;
        height: 10px;
        width: 10px;
        opacity: 1;
      }
      to{
        top: 50%;
        right: -10%;
        height: 60px;
        width: 60px;
        opacity: 0;
      }
    }
    @media (max-width: 767px), (min-width: 768px) and (max-width: 819px) and (max-height: 375px) {
      @keyframes fly-tumblr-2 {
        from{
          top: $position-animation;
          right: $position-animation;
          height: 0px;
          width: 0px;
          opacity: 1;
        }
        to{
          top: 50%;
          right: -15%;
          height: 40px;
          width: 40px;
          opacity: 0;
        }
      }
    }
    //анимация летающей lj соцсети//
    &.circle-lj{
      animation-name: fly-lj;
      animation-delay: 0s;
    }
    @keyframes fly-lj {
      from{
        top: $position-animation;
        left: $position-animation;
        height: 10px;
        width: 10px;
        opacity: 1;
      }
      to{
        top: 50%;
        left: -10%;
        height: 60px;
        width: 60px;
        opacity: 0;
      }
    }
    @media (max-width: 767px), (min-width: 768px) and (max-width: 819px) and (max-height: 375px) {
      @keyframes fly-lj {
        from{
          top: $position-animation;
          left: $position-animation;
          height: 0px;
          width: 0px;
          opacity: 1;
        }
        to{
          top: 50%;
          left: -15%;
          height: 40px;
          width: 40px;
          opacity: 0;
        }
      }
    }



    &.circle-lj-2{
      animation-name: fly-lj-2;
      animation-delay: 2.6s;
    }
    @keyframes fly-lj-2 {
      from{
        top: $position-animation;
        left: $position-animation;
        height: 10px;
        width: 10px;
        opacity: 1;
      }
      to{
        top: 15%;
        left: -10%;
        height: 60px;
        width: 60px;
        opacity: 0;
      }
    }
    @media (max-width: 767px), (min-width: 768px) and (max-width: 819px) and (max-height: 375px) {
      @keyframes fly-lj-2 {
        from{
          top: $position-animation;
          left: $position-animation;
          height: 0px;
          width: 0px;
          opacity: 1;
        }
        to{
          top: 15%;
          left: -15%;
          height: 40px;
          width: 40px;
          opacity: 0;
        }
      }
    }

    //анимация летающей in соцсети//
    &.circle-in{
      animation-name: fly-in;
      animation-delay: 1.5s;
    }
    @keyframes fly-in {
      from{
        top: $position-animation;
        left: $position-animation;
        height: 10px;
        width: 10px;
        opacity: 1;
      }
      to{
        top: 15%;
        left: -10%;
        height: 60px;
        width: 60px;
        opacity: 0;
      }
    }
    @media (max-width: 767px), (min-width: 768px) and (max-width: 819px) and (max-height: 375px) {
      @keyframes fly-in {
        from{
          top: $position-animation;
          left: $position-animation;
          height: 0px;
          width: 0px;
          opacity: 1;
        }
        to{
          top: 15%;
          left: -15%;
          height: 40px;
          width: 40px;
          opacity: 0;
        }
      }
    }

    &.circle-in-2{
      animation-name: fly-in-2;
      animation-delay: 2.8s;
    }
    @keyframes fly-in-2 {
      from{
        top: $position-animation;
        left: $position-animation;
        height: 10px;
        width: 10px;
        opacity: 1;
      }
      to{
        top: -10%;
        left: 15%;
        height: 90px;
        width: 90px;
        opacity: 0;
      }
    }
    @media (max-width: 767px), (min-width: 768px) and (max-width: 819px) and (max-height: 375px) {
      @keyframes fly-in-2 {
        from{
          top: $position-animation;
          left: $position-animation;
          height: 0px;
          width: 0px;
          opacity: 1;
        }
        to{
          top: -10%;
          left: 15%;
          height: 60px;
          width: 60px;
          opacity: 0;
        }
      }
    }
    // ---- левое крыло конец -----------//

    // ---- правое крыло начало -----------//

    //анимация летающей twitter соцсети//
    &.circle-pint{
      animation-name: fly-pint;
      //animation-delay: 0.3s;
    }
    @keyframes fly-pint {
      from{
        top: $position-animation;
        right: $position-animation;
        height: 10px;
        width: 10px;
        opacity: 1;
      }
      to{
        top: 80%;
        right: -10%;
        height: 60px;
        width: 60px;
        opacity: 0;
      }
    }
    @media (max-width: 767px), (min-width: 768px) and (max-width: 819px) and (max-height: 375px) {
      @keyframes fly-pint {
        from{
          top: $position-animation;
          right: $position-animation;
          height: 0px;
          width: 0px;
          opacity: 1;
        }
        to{
          top: 80%;
          right: -15%;
          height: 40px;
          width: 40px;
          opacity: 0;
        }
      }
    }


    &.circle-pint-2{
      animation-name: fly-pint-2;
      animation-delay: 1.75s;
    }
    @keyframes fly-pint-2 {
      from{
        top: $position-animation;
        right: $position-animation;
        height: 10px;
        width: 10px;
        opacity: 1;
      }
      to{
        top: -10%;
        right: 15%;
        height: 60px;
        width: 60px;
        opacity: 0;
      }
    }
    @media (max-width: 767px), (min-width: 768px) and (max-width: 819px) and (max-height: 375px) {
      @keyframes fly-pint-2 {
        from{
          top: $position-animation;
          right: $position-animation;
          height: 0px;
          width: 0px;
          opacity: 1;
        }
        to{
          top: -10%;
          right: 15%;
          height: 40px;
          width: 40px;
          opacity: 0;
        }
      }
    }
    //анимация летающей OK соцсети//
    &.circle-tg{
      animation-name: fly-tg;
      animation-delay: 0.4s;
    }
    @keyframes fly-tg {
      from{
        top: $position-animation;
        right: $position-animation;
        height: 10px;
        width: 10px;
        opacity: 1;
      }
      to{
        top: 15%;
        right: -10%;
        height: 75px;
        width: 75px;
        opacity: 0;
      }
    }
    @media (max-width: 767px), (min-width: 768px) and (max-width: 819px) and (max-height: 375px) {
      @keyframes fly-tg {
        from{
          top: $position-animation;
          right: $position-animation;
          height: 0px;
          width: 0px;
          opacity: 1;
        }
        to{
          top: 15%;
          right: -15%;
          height: 40px;
          width: 40px;
          opacity: 0;
        }
      }
    }


    &.circle-tg-2{
      animation-name: fly-tg-2;
      animation-delay: 1.5s;
    }
    @keyframes fly-tg-2 {
      from{
        top: $position-animation;
        right: $position-animation;
        height: 10px;
        width: 10px;
        opacity: 1;
      }
      to{
        top: 80%;
        right: -10%;
        height: 75px;
        width: 75px;
        opacity: 0;
      }
    }
    @media (max-width: 767px), (min-width: 768px) and (max-width: 819px) and (max-height: 375px) {
      @keyframes fly-tg-2 {
        from{
          top: $position-animation;
          right: $position-animation;
          height: 0px;
          width: 0px;
          opacity: 1;
        }
        to{
          top: 80%;
          right: -15%;
          height: 40px;
          width: 40px;
          opacity: 0;
        }
      }
    }

    //анимация летающей VK соцсети//
    &.circle-fb{
      animation-name: fly-fb;
      animation-delay: 0.7s;
    }
    @keyframes fly-fb {
      from{
        top: $position-animation;
        right: $position-animation;
        height: 10px;
        width: 10px;
        opacity: 1;
      }
      to{
        top: -10%;
        right: 15%;
        height: 90px;
        width: 90px;
        opacity: 0;
      }
    }
    @media (max-width: 767px), (min-width: 768px) and (max-width: 819px) and (max-height: 375px) {
      @keyframes fly-fb {
        from{
          top: $position-animation;
          right: $position-animation;
          height: 0px;
          width: 0px;
          opacity: 1;
        }
        to{
          top: -10%;
          right: 15%;
          height: 60px;
          width: 60px;
          opacity: 0;
        }
      }
    }

    &.circle-fb-2{
      animation-name: fly-fb-2;
      animation-delay: 2.1s;
    }
    @keyframes fly-fb-2 {
      from{
        top: $position-animation;
        right: $position-animation;
        height: 10px;
        width: 10px;
        opacity: 1;
      }
      to{
        top: 50%;
        right: -10%;
        height: 60px;
        width: 60px;
        opacity: 0;
      }
    }
    @media (max-width: 767px), (min-width: 768px) and (max-width: 819px) and (max-height: 375px) {
      @keyframes fly-fb-2 {
        from{
          top: $position-animation;
          right: $position-animation;
          height: 0px;
          width: 0px;
          opacity: 1;
        }
        to{
          top: 50%;
          right: -15%;
          height: 40px;
          width: 40px;
          opacity: 0;
        }
      }
    }
    //анимация летающей lj соцсети//
    &.circle-inst{
      animation-name: fly-inst;
      animation-delay: 0.9s;
    }
    @keyframes fly-inst {
      from{
        top: $position-animation;
        right: $position-animation;
        height: 10px;
        width: 10px;
        opacity: 1;
      }
      to{
        top: 50%;
        right: -10%;
        height: 100px;
        width: 100px;
        opacity: 0;
      }
    }
    @media (max-width: 767px), (min-width: 768px) and (max-width: 819px) and (max-height: 375px) {
      @keyframes fly-inst {
        from{
          top: $position-animation;
          right: $position-animation;
          height: 0px;
          width: 0px;
          opacity: 1;
        }
        to{
          top: 50%;
          right: -15%;
          height: 60px;
          width: 60px;
          opacity: 0;
        }
      }
    }



    &.circle-inst-2{
      animation-name: fly-inst-2;
      animation-delay: 2.6s;
    }
    @keyframes fly-inst-2 {
      from{
        top: $position-animation;
        right: $position-animation;
        height: 10px;
        width: 10px;
        opacity: 1;
      }
      to{
        top: 15%;
        right: -10%;
        height: 60px;
        width: 60px;
        opacity: 0;
      }
    }
    @media (max-width: 767px), (min-width: 768px) and (max-width: 819px) and (max-height: 375px) {
      @keyframes fly-inst-2 {
        from{
          top: $position-animation;
          right: $position-animation;
          height: 0px;
          width: 0px;
          opacity: 1;
        }
        to{
          top: 15%;
          right: -15%;
          height: 40px;
          width: 40px;
          opacity: 0;
        }
      }
    }

    //анимация летающей in соцсети//
    &.circle-mail{
      animation-name: fly-mail;
      animation-delay: 1.5s;
    }
    @keyframes fly-mail {
      from{
        top: $position-animation;
        right: $position-animation;
        height: 10px;
        width: 10px;
        opacity: 1;
      }
      to{
        top: 15%;
        right: -10%;
        height: 60px;
        width: 60px;
        opacity: 0;
      }
    }
    @media (max-width: 767px), (min-width: 768px) and (max-width: 819px) and (max-height: 375px) {
      @keyframes fly-mail {
        from{
          top: $position-animation;
          right: $position-animation;
          height: 0px;
          width: 0px;
          opacity: 1;
        }
        to{
          top: 15%;
          right: -15%;
          height: 40px;
          width: 40px;
          opacity: 0;
        }
      }
    }

    &.circle-mail-2{
      animation-name: fly-mail-2;
      animation-delay: 2.8s;
    }
    @keyframes fly-mail-2 {
      from{
        top: $position-animation;
        right: $position-animation;
        height: 10px;
        width: 10px;
        opacity: 1;
      }
      to{
        top: -10%;
        right: 15%;
        height: 60px;
        width: 60px;
        opacity: 0;
      }
    }
    @media (max-width: 767px), (min-width: 768px) and (max-width: 819px) and (max-height: 375px) {
      @keyframes fly-mail-2 {
        from{
          top: $position-animation;
          right: $position-animation;
          height: 0px;
          width: 0px;
          opacity: 1;
        }
        to{
          top: -10%;
          right: 15%;
          height: 40px;
          width: 40px;
          opacity: 0;
        }
      }
    }
    // ---- правое крыло конец -----------//
  }
}
.banner__text-container {
  padding-top: 40px;
  margin: 0 auto;
}
.banner__slogans-list {
  display: flex;
  justify-content: space-between;
  list-style: none;
  text-decoration: underline;
  font-size: 18px;
  color: $blue;
}
.banner__slogan-item {
  a {
    color: $blue;
    transition: all 0.15s linear 0s;
  }

  a:hover,
  a:active,
  a:focus {
    color: $green;
  }
}
.banner__text-title {
  padding: 0;
  font-size: 60px;
  text-transform: uppercase;
}
.banner__text {
  font-size: 30px;
  line-height: 1;
}
.banner__text-wrapper--mobile {
  display: none;
}
.banner__price-container {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}
.banner__price {
  display: flex;
  align-items: center;
  font-size: 53px;
  color: $blue;
  margin-left: 10px;

  span {
    font-family: "Segoe UI";
    font-size: 15px;
  }

  .price {
    font-size: 27px;
  }
}
.banner__currency-container {
  display: flex;
  flex-direction: column;
  font-size: 21px;
  color: $blue;
  margin-left: 10px;

  & span:first-child {
    border-bottom: 1px dashed blue;
  }
}
.banner__description {
  font-size: 21px;
  color: $blue;
  line-height: 25px;
}
.banner__button-container {
  display: flex;
  gap: 30px;
  margin-bottom: 30px;
  margin-top: 60px;
}
.banner__small-description {
  font-size: 16px;
  color: gray;
  font-style: italic;
}
.banner__free {
  font-size: 18px;
  font-style: italic;
  margin-bottom: 2.5rem;

  u {
    margin-left: 5px;
  }
}
.banner__mobile-logo {
  display: none;
}

.banner__socials {
  display: flex;
  margin-top: 70px;
  padding: 50px 14px;
  border-radius: 20px;
  max-height: 440px;
}
.banner__socials-list {
  & p {
    transform: rotate(-90deg);
    transform-origin: left top;
    font-family: "Lato Medium";
    font-size: 11px;
  }
}
.banner__socials-ul{
  display: flex;
  flex-direction: column;
  gap: 5px;
  list-style: none;

  & > li {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 21px;
    height: 21px;
    border-radius: 50%;
    font-size: 12px;
  }
}
.banner__socials-icon {
  height: 10px;
  width: 12px;
}
.banner__socials--mobile {
  display: none;
}

@media (max-width: 1052px) {
  .banner__socials {
    display: none;
  }
  #header-carousel {
    background: url("../images/circle-gradient.png") no-repeat -300px -300px;
  }
}
@media (max-width: 767px) {
  .banner,
  .banner__slogans-list,
  .banner__small-description,
  .animation-ss {
    display: none;
  }

  .banner__text-wrapper {
    display: none;
  }

  .banner__text-wrapper--mobile {
    display: grid;
    grid-template-columns: 117px 1fr;
    grid-template-rows: 1fr;
    gap: 0 20px;
    margin-bottom: 30px;
    padding-top: 20px;
  }
  .banner__mobile-logo {
      display: inline-block;
      width: 118px;
      grid-row-start: 1;
      grid-row-end: 3;
      margin: 0;
  }
  .banner__text-title {
    font-size: 34px;
    grid-row-start: 1;
    grid-row-end: 2;
    margin: 0 0 20px 0;
    text-align: left;
  }
  .banner__text-container {
    padding-top: 0;
  }
  .banner__text {
    font-size: 15px;
    margin: 0 auto 0 0;
  }
  .banner__text-second {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .banner__price-container {
    display: flex;
    flex-direction: column;
    margin: 0;
  }
  .banner__price {
    margin: 0 auto 0 0;
    align-items: first baseline;
  }
  .banner__description {
    font-size: 15px;
    line-height: 18px;
    color: #054D90;
    margin-bottom: 15px;
  }
  .banner__button-container {
    display: flex;
    gap: 30px;
    margin: 30px auto 15px;
  }
  .banner__free {
    font-size: 13px;
    color: #707B91;
    text-align: center;
  }

  .banner__socials--mobile {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    margin-bottom: 20px;
  }
  .banner__socials-ul {
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    margin: 0;
    padding-right: 10px;
  }
  .banner__socials-list {
    display: grid;
    grid-template-columns: 54px 1fr;

    & p {
      transform: none;
    }
  }

  #header-carousel {
    background: url("../images/circle-gradient.png") no-repeat -100px -125px;
    background-size: auto 100%;
    padding: 0 15px;
  }
}

.owl-carousel{
  &.owl-loaded {
    .owl-item {
      img.page-banner {
        width: 25vw;
        @media (max-width: 767px) {
          width: 67vw;
        }
        //@media (min-width: 1440px) {
        //  width: 360px;
        //}
      }
    }

    @media (max-width: 767px) {
      padding: 0px;
    }
  }
  .under-banner {
    padding: 0px;
    @media (max-width: 1199px) {
      margin: 0px;
    }
    .gap{
      padding: 0px 12px;
      @media (max-width: 767px){
        padding: 0px;
      }
      .banner-produkt {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        height: 13vw;
        margin-bottom: 17px;
        @media (max-width: 1199px) {
          height: 15vw;
        }
        @media (max-width: 767px){
          height: 50vw;
        }
        &.wood {
          background: url("image_content/wood.jpg") no-repeat center center;
          background-size: 100% 100%;
        }
        &.stone {
          background: url("image_content/stone.jpg") no-repeat center center;
          background-size: 100% 100%;
        }
        &.herringbone {
          background: url("image_content/herringbone.jpg") no-repeat center center;
          background-size: 100% 100%;
        }
      }
    }
  }
  .more  {
    font-family: "FuturaMediumC";
    width: 168px;
    color: white;
    text-transform: uppercase;
    text-align: center;
    font-weight: 400;
    display: block;
    margin-bottom: 60px;
    margin-top: 40px;
    padding: 8px 20px 8px 20px;
    background-color: #F27F4C;
    border-radius: 10px;
    &:hover{
      background-color: #F3763F;
    }
    @media (max-width: 991px) {
      margin-bottom: 20px;
    }
    @media (min-width: 768px) and (max-width: 991px) {
      margin-bottom: 0;    }
    @media (min-width: 992px) and (max-width: 1200px) {
      margin-bottom: 100px;
    }
    @media (max-width: 767px){
      margin-left: auto;
      margin-right: auto;
      // width: 85%;
      margin-bottom: 8vw;
      width: 160px;
      font-family: "FuturaBookC";
      color: white;
      font-weight: lighter;
    }
  }
  .container-banner{
    padding: 0px;
    .mobil-page-1{
      @media (max-width: 767px){
        display: flex;
        justify-content: center;
      }
    }
    .annotation{
      text-align: center;
      padding-bottom: 10vw;
      @media (max-width: 419px){
        padding-bottom: 30vw;
      }
    }

    @media (max-width: 991px){
      //padding: 0px;
    }
    @media (max-width: 767px){
      //padding: 0px;
      text-align: center;
    }

  }
  .banner-note{
    font-family: "FuturaDemiC";
    text-transform: uppercase;
    font-weight: 400;
    text-align: left;
    display: block;
    font-size: 4vw;
    color: #ffffff;
    @media (min-width: 768px) and (max-width: 991px) {

    }
    @media (min-width: 992px) and (max-width: 1200px) {
      font-size: 40px;
    }
    @media (min-width: 1440px) {
      font-size: 57px;
    }
    @media (max-width: 767px){
      font-size: 42px;
      margin-top: 40px;
      text-align: center;
    }
  }
  .banner-text-product{
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: 400;
    display: block;
    font-size: 16px;
    color: black;
    text-align: justify;
  }
  .banner-model{
    text-shadow: #000 0px 4px 6px;
    //text-transform: uppercase;
    font-weight: 600;
    font-size: 2vw;
    color: #ffffff;
    @media (min-width: 420px) and (max-width: 767px){
      font-size: 3vw;
      justify-content: center;
      color: #ffffff;
    }
    @media (min-width: 768px) and (max-width: 1023px){
      font-size: 4vw;
      justify-content: center;
      color: #ffffff;
    }
    @media (min-width: 1024px) and (max-width: 1099px) {
      font-size: 3vw;
    }
    @media (max-width: 419px) {
      font-size: 5vw;
    }
  }
}
