.simple {
  position: relative;
  padding: 40px 80px 80px;
  background: linear-gradient(82.97deg, #B0C6DE -4.72%, #E7EBF7 33.09%, rgba(2, 181, 9, 0.31) 105.29%);
}
.simple__container {
  position: relative;
  display: flex;
  justify-content: space-around;
  font-size: 21px;
}
.simple__column {
  display: flex;
  justify-content: center;
}
.simple__column:first-child {
  align-items: center;
  text-align: center;
}
.simple__column:first-child > p {
  max-width: 170px;
  line-height: 25px;
}
.simple__list {
  display: flex;
  flex-direction: column;
  gap: 13px;
  list-style: none;
}
.simple__list-arrow {
  width: 26px;

  &.simple__list-arrow--mobile {
    display: none;
  }
}
.simple__list-item {
  display: flex;
}
.simple__item-img {
  margin: 0 10px;
}
.simple__list-item-wrapper {
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border: 1px dashed $blue;
  border-radius: 30px;
  width: 280px;
  height: 60px;
  margin: 0 40px 0 30px;
}
.simple__column-text {
  position: relative;
  width: 200px;
  text-align: center;
  margin: auto 35px;
  line-height: 25px;
  color: $blue;
  border: 1px dashed $blue;
  border-radius: 30px;
  padding: 20px;
}
.simple__result-text {
  text-transform: uppercase;
  font-weight: 700;
  margin: auto;
}
.simple-icon {
  position: absolute;
  max-width: 80px;

  &.circle-vk {
    top: 20%;
    right: 40%;
    width: 77px;
  }
  &.circle-ok {
    top: 70%;
    right: 35%;
    width: 65px;
  }
  &.circle-in {
    top: 15%;
    left: 15%;
    width: 40px;
  }
  &.circle-tumblr {
    bottom: 10%;
    left: 11%;
    width: 48px;
  }
  &.circle-lj {
    left: 20%;
    top: 50%;
    width: 52px;
  }
  &.circle-tw {
    top: 41%;
    left: 49%;
    width: 35px;
  }
  &.circle-mail {
    right: 28%;
    width: 57px;
    top: 37%;
  }
  &.circle-tg {
    right: 7%;
    top: 50%;
    width: 62px;
  }
  &.circle-pint {
    top: 20%;
    right: 15%;
    width: 70px;
  }
  &.circle-youtube {
    bottom: 15%;
    left: 33%;
  }
  &.circle-tt {
    right: 15%;
    width: 68px;
    bottom: 42px;
  }
}

@media (max-width: 768px) {
  .simple {
    padding: 22px 10px 20px;
    background: linear-gradient(82.97deg, rgba(176, 198, 222, 0.8) -4.72%, rgba(2, 181, 9, 0.248) 105.29%);
  }
  .simple__container {
    flex-direction: column;
  }
  .simple__column {
    flex-direction: column;
    align-items: center;
  }
  .simple__column-text {
    border-radius: 20px;
    margin: 0 0 30px 0;
    font-size: 15px;
    width: auto;
  }
  .simple__column:first-child > p {
    position: relative;
    font-size: 15px;
    max-width: initial;
    height: auto;
    margin-bottom: 20px;
  }
  .simple__list {
    flex-direction: row;
    margin-bottom: 20px;
  }
  .simple__list-arrow {
    transform: rotate(90deg);

    &.simple__list-arrow--desktop {
      opacity: 0;
    }
  }
  .simple__list-item {
    flex-direction: column;
    align-items: center;
    width: auto;
  }
  .simple__list-item-wrapper {
    flex-direction: column;
    text-align: center;
    border-radius: 20px;
    width: auto;
    height: auto;
    margin: 30px 0;
    padding: 8px;
  }
  .simple__item-text {
    font-size: 13px;
    text-align: center;
  }
  .simple__result-text {
    margin: 30px auto 40px;
    font-size: 15px;
  }
  .simple-icon {
    &.circle-youtube {
      left: 5%;
      width: 33px;
    }
    &.circle-tumblr {
      bottom: 8%;
      left: 15%;
      width: 37px;
    }
    &.circle-tw {
      top: initial;
      bottom: 2%;
      left: 20%;
      width: 27px;
    }
    &.circle-mail {
      top: initial;
      left: 28%;
      width: 40px;
      bottom: 6%;
    }
    &.circle-ok {
      top: initial;
      bottom: 16%;
      left: 28%;
      width: 44px;
    }
    &.circle-tt {
      left: 42%;
      width: 41px;
      bottom: 2%;
    }
    &.circle-in {
      top: initial;
      bottom: 10%;
      left: 45%;
      width: 28px;
    }
    &.circle-tg {
      top: initial;
      right: 29%;
      bottom: 5%;
      width: 49px;
    }
    &.circle-vk {
      top: initial;
      bottom: 14%;
      right: 22%;
      width: 36px;
    }
    &.circle-lj {
      top: initial;
      left: initial;
      right: 18%;
      bottom: 5%;
      width: 28px;
    }
    &.circle-pint {
      top: initial;
      bottom: 11%;
      right: 7%;
      width: 35px;
    }
  }
}

@media (min-width: 768px) and (max-width: 1180px) {
  .simple__container {
    gap: 30px 0;
    flex-direction: column;
  }
  .simple__column {
    align-items: center;
    flex-direction: column;

    &:first-child > p {
      max-width: max-content;
      margin: 0;
    }
  }
  .simple__column-text {
    margin: 0 0 30px;
  }
  .simple__list {
    flex-direction: row;
  }
  .simple__list-item {
    flex-direction: column;
    align-items: center;
  }
  .simple__list-item-wrapper {
    flex-direction: column;
    text-align: center;
    width: auto;
    height: auto;
    margin: 30px 0;
    padding: 15px 10px;
  }
  .simple__list-arrow {
    transform: rotate(90deg);

    &.simple__list-arrow--desktop {
      opacity: 0;
    }
  }
}