.spider{
  display:none;
}
.flex-spider{
  display: flex;
  flex-wrap: wrap;
}
@media(min-width: 800px){
  .spider{
    display:block;
    position: absolute;
    border: 1px solid gray;
    &:before{
      display: block;
      width: 6px;
      height: 6px;
      border-radius: 6px;
      background: gray;
      content:"";
      position: absolute;
    }
    &:after{
      display: block;
      width:6px;
      height: 6px;
      border-radius: 6px;
      background: gray;
      content:"";
      position: absolute;
    }
  }
  .spider1{
       width: 100px;
       height: 50px;
       right: -120px;
       top: 50px;
       border-bottom: 0;
       border-left: 0;
       &:before{
         top:-3px;
       }
       &:after{
         bottom:-3px;
         right:-3px;
       }
     }
  .spider2{
    width: 20px;
    height: 0;
    right: -40px;
    top: 50px;
    border-bottom: 0;
    border-left: 0;
    &:before{
      top:-3px;
    }
    &:after{
      bottom:-3px;
      right:-3px;
    }
  }
  .spider3{
    width: 100px;
    height: 50px;
    right: -120px;
    top: 20px;
    border-top: 0;
    border-left: 0;
    &:before{
      bottom:-3px;
    }
    &:after{
      top:-3px;
      right:-3px;
    }
  }
  .spider4{
    width: 100px;
    height: 50px;
    left: -120px;
    top: 50px;
    border-bottom: 0;
    border-right: 0;
    &:before{
      left:-3px;
      bottom:-3px;
    }
    &:after{
      top:-3px;
      right:-3px;
    }
  }
  .spider5{
    width: 20px;
    height:0px;
    left: -40px;
    top: 50px;
    border-bottom: 0;
    border-left: 0;
    &:before{
      top:-3px;
    }
    &:after{
      bottom:-3px;
      right:-3px;
    }
  }

  .spider6{
    width: 100px;
    height: 50px;
    left: -120px;
    top: 38px;
    border-top: 0;
    border-right: 0;
    &:before{
      bottom:-3px;
      right:-3px;
    }
    &:after{
      top:-3px;
      left:-3px;
    }
  }
  .weapon{
    position: relative
  }
}