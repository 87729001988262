.schedule {
  text-align: center;
  padding: 45px 60px 65px;
  background-size: contain;
  background:
          center / contain no-repeat url('../images/background-line.svg'),
          linear-gradient(89.99deg, #E7EBF7 0.02%, #B0C6DE 74.65%, #B0C6DE 99.99%);
}
.schedule__column-title {
  font-size: 21px;
  color: $blue;
}
.schedule__column-text {
  max-width: 250px;
  font-size: 18px;
  line-height: 22px;
}
.schedule__column {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.schedule__column-img {
  width: 60px;
  margin-bottom: 25px;
}
.schedule__graph-wrapper {
  display: flex;
  flex-direction: column;
}
.schedule__graph--mobile {
  display: none;
}

@media (max-width: 767px) {
  .schedule {
    padding: 30px 20px 40px;
    background: no-repeat url("../images/background-line.svg"), linear-gradient(89.99deg, #E7EBF7 0.02%, #B0C6DE 74.65%, #B0C6DE 99.99%);
  }
  .schedule__row  {
    overflow-y: scroll;
    scrollbar-width: none;
    flex-wrap: nowrap;

    &::-webkit-scrollbar {
      display: none;
    }
  }
  .schedule__column-title {
    font-size: 15px;
    margin: 0;
  }
  .schedule__column-text {
    font-size: 13px;
    line-height: 18px;
  }
  .schedule__column-img {
    margin-bottom: 5px;
  }
  .schedule__graph {
    display: none;
  }
  .schedule__graph--mobile {
    display: inline-block;
    width: 100%;
  }
}