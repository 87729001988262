html{
  overflow-x: hidden;
  font-family: 'Ubuntu', sans-serif;
  font-size: 14px;
}
@media (min-width: 800px) {
  html{
    font-size: 16px;
  }
}

.section-header{
  text-align: center;
}

.gradient{
    font-size: 72px;
    color:$gradientColor1;
    background: -webkit-linear-gradient(90deg, $gradientColor1, $gradientColor2);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.right{
  text-align: right;
}
p {
  line-height: 18px;
  //font-family: "Roboto";
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  @media (min-width: 1600px){
    font-size: 1.2vw;
    line-height: 1.3vw;
  }
}
@media (max-width: 500px){
  .size {
    //white-space: nowrap; /* Запрещаем перенос строк */
    //overflow: hidden; /* Обрезаем все, что не помещается в область */
    //text-overflow: ellipsis; /* Добавляем многоточие */
  }
}
@media (min-width: 768px) and (max-width: 991px){
  .size {
    white-space: nowrap; /* Запрещаем перенос строк */
    overflow: hidden; /* Обрезаем все, что не помещается в область */
    text-overflow: ellipsis; /* Добавляем многоточие */
  }
}
h1,h3{
  font-family: "Lato Medium";
  padding-top: 2.2em;
  padding-bottom: 1.2em;
}
h2{
  //font-family: "Roboto";
  padding-top: 2.2em;
  //padding-bottom: 1.2em;
  @media(min-width: 1600px){
    font-size: 2vw;
  }
}
.section-header{
  color:$headersColor;
  font-weight: normal;
  font-size: 2rem;
  padding-top: 2.5em;
  padding-bottom: 0.8em;
  @media(max-width: 767px){
    font-size: 1.5rem;
    padding-top: 3.0em;
  }
  @media(min-width: 1600px){
    font-size: 2vw;
  }
  &:after{
    content:"";
    display:block;
    background: #0075FF;
    width: 100px;
    max-width: 30%;
    height: 2px;
    margin: 0.9em auto 0;
  }
}

@font-face {
  font-family: 'Lato Bold';
  src: url('../fonts/Lato-Bold.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Lato Italic';
  src: url('../fonts/Lato-Italic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: 'Lato Medium';
  src: url('../fonts/Lato-Medium.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Lato Regular';
  src: url('../fonts/Lato-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

/* ---icomoon START--- */
@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?wy7hz7');
  src:  url('fonts/icomoon.eot?wy7hz7#iefix') format('embedded-opentype'),
  url('fonts/icomoon.ttf?wy7hz7') format('truetype'),
  url('fonts/icomoon.woff?wy7hz7') format('woff'),
  url('fonts/icomoon.svg?wy7hz7#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-reddit-alien:before {
  content: "\e900";
}
.icon-blogger:before {
  content: "\e901";
}
.icon-brand:before {
  content: "\e901";
}
.icon-social:before {
  content: "\e901";
}
.icon-livejournal:before {
  content: "\e902";
}
.icon-tiktok:before {
  content: "\e903";
}
.icon-my-world:before {
  content: "\e904";
}
.icon-mail-ru:before {
  content: "\e905";
}
.icon-yandex:before {
  content: "\e906";
}
.icon-egg:before {
  content: "\e907";
}
.icon-vk-market-squre:before {
  content: "\e908";
}
.icon-filtr:before {
  content: "\e909";
}
.icon-groop:before {
  content: "\e90a";
}
.icon-schedule:before {
  content: "\e90b";
}
.icon-stamp:before {
  content: "\e90c";
}
.icon-template:before {
  content: "\e90d";
}
.icon-trash:before {
  content: "\e90e";
}
.icon-sort-amount-desc:before {
  content: "\ea46";
}
.icon-tumblr:before {
  content: "\eab9";
}
.icon-calendar:before {
  content: "\f073";
}
.icon-comments:before {
  content: "\f086";
}
.icon-thumbs-o-up:before {
  content: "\f087";
}
.icon-twitter:before {
  content: "\f099";
}
.icon-facebook:before {
  content: "\f09a";
}
.icon-feed:before {
  content: "\f09e";
}
.icon-bars:before {
  content: "\f0c9";
}
.icon-linkedin:before {
  content: "\f0e1";
}
.icon-sitemap:before {
  content: "\f0e8";
}
.icon-angle-double-down:before {
  content: "\f103";
}
.icon-mobile:before {
  content: "\f10b";
}
.icon-spinner:before {
  content: "\f110";
}
.icon-rouble:before {
  content: "\f158";
}
.icon-youtube:before {
  content: "\f167";
}
.icon-instagram:before {
  content: "\f16d";
}
.icon-vk:before {
  content: "\f189";
}
.icon-paper-plane:before {
  content: "\f1d8";
}
.icon-connectdevelop:before {
  content: "\f20e";
}
.icon-pinterest-p:before {
  content: "\f231";
}
.icon-odnoklassniki:before {
  content: "\f263";
}
.icon-livejournal1:before {
  content: "\1f300";
}
/* ---icomoon END--- */

.hidden {
  display: none;
}

section a.nodecor{
  color: $black;
  text-decoration: none;
  &:hover{
    color: $gradientColor2;
  }
}
.annotation-potential{
  text-align: center;
  margin-top: 20px;
}
.is-centered{
  text-align: center;
  justify-content: center;
}
.is-uppercase{
  text-transform: uppercase;
}