.popupinfo {
  position: absolute;
  width: 80vmax;
  height: 80vmin;
}

.cookie_notice {
  display: none;
  position: fixed;
  z-index: 9999999;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  font-size: 15px;
  font-family: "Open Sans", sans-serif;
  color: #FFF;
  background: $blue;
  padding: 10px 20px;
}
/* Оформление кнопок */
.cookie_btn {
  display: inline-block;
  margin: 10px 6px 4px 6px;
  text-decoration: none;
  position: relative;
  font-size: 13px;
  padding: 4px 12px;
  color: #FFF;
  font-weight: bold;
  text-transform: uppercase;
  background: $blue;
  border: 2px solid #fff;
  border-radius: 5px;
}
.cookie_btn:hover {
  color: #FFF;
}
.cookie_btn:after {
  position: absolute;
  height: 2px;
  left: 50%;
  background: #FFF;
  bottom: -6px;
  content: "";
  transition: all 280ms ease-in-out;
  width: 0;
}

.cookie_btn:hover:after {
  width: 100%;
  left: 0;
}

@media (max-width: 767px) {
  .cookie_btn,
  .cookie_notice {
    font-size: 13px;
  }
}