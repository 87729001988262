.flip-container:hover .flipper{
  transform: rotateY(180deg);
}
.flipper {
  transition: 0.4s;
  transform-style: preserve-3d;
  position: relative;
}


.front, .back {
  backface-visibility: hidden;
}

.possib{
  margin-right: -5px;
  margin-left: -5px;
}
.possibility {
  font-family: "Lato Medium";
  text-align: center;
  font-size: 1.1vw;
  line-height: 1.8vw;
  @media (min-width: 992px) {
    width: 16.06666666666666666666%;
    margin: 0px 0.3% 0.6% 0.3%;
  }
  @media (min-width: 500px) and (max-width: 991px) {
    width: 32.733333333333333333333333333%;
    margin: 0px 0.3% 0.6% 0.3%;
    font-size: 2.2vw;
    line-height: 3.5vw;
  }
  @media (min-width: 320px) and (max-width: 499px) {
    width: 48.4%;
    margin: 0px 0.8% 1.6% 0.8%;
    font-size: 3.2vw;
    line-height: 4.8vw;
  }
  .front {
    background-size: 100% auto;
    background-image: radial-gradient(circle at center, #014BD9, #02266C, #031332, #000D25);
    z-index: 2;
    transform: rotateY(0deg);
    .part {
      display: flex;
      position: relative;
      justify-content: center;
      color: #fff;
      text-transform: uppercase;
      font-weight: 600;
      height: 16vw;
      padding: 1vw;
      @media (min-width: 500px) and (max-width: 991px) {
        height: 33vw;
        padding: 3vw;
      }
      @media (min-width: 320px) and (max-width: 499px) {
        height: 50vw;
        padding: 3vw;
      }
    }
    .image-possibility{
      position: absolute;
      top: 5.2vw;
      margin-left: auto;
      margin-right: auto;
      width: 50%;
      @media (min-width: 500px) and (max-width: 991px) {
        top: 11vw;
      }
      @media (min-width: 320px) and (max-width: 499px) {
        top: 15vw;
      }
    }
  }
  .back {
    width: 100%;
    //background: #EFEFEF;
    background: #ffffff;
    box-shadow: inset 0 0 6px #0058FF;
    border: 2px solid #D7D7D7;
    transform: rotateY(180deg);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    &:hover{
      text-decoration: none;
    }
    .part {
      display: flex;
      //position: relative;
      justify-content: center;
      color: #0058FF;
      text-transform: uppercase;
      font-weight: 600;
      height: 16vw;
      padding: 1vw;
      .annotation {
        text-align: left;
        color: #000;
        text-transform: none;
        line-height: 1.2vw;
        font-weight: 400;
        padding-top: 15px;
        &.full-funktion{
          margin-top: -30px;
        }
        @media (min-width: 320px) and (max-width: 499px) {
          line-height: 4.2vw;
        }
        @media (min-width: 500px) and (max-width: 991px) {
          line-height: 2.7vw;
          padding-top: 5px;
        }
      }
      @media (min-width: 500px) and (max-width: 991px) {
        height: 33vw;
        padding: 3vw;
      }
      @media (min-width: 320px) and (max-width: 499px) {
        height: 50vw;
        padding: 3vw;
      }
    }

    .image-possibility{
      position: absolute;
      bottom: 1.0vw;
      right: 1.0vw;
      width: 20%;
      animation-name: rotare;
      animation-duration: 1.5s;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
      @keyframes rotare {
        0%{
          filter:brightness(100%) drop-shadow(0 12px 10px #ffffff);
        }
        80%{
          filter:brightness(100%) drop-shadow(0 12px 10px #ffffff);
        }
        90%{
          filter:brightness(200%) drop-shadow(0 12px 50px #ffffff);
        }
        100%{filter:brightness(100%) drop-shadow(0 12px 10px #ffffff);
        }
      }
      @media (min-width: 500px) and (max-width: 991px) {
        top: 25vw;
        right: 1.5vw;
      }
      @media (min-width: 320px) and (max-width: 499px) {
        top: 38vw;
        right: 2.5vw;
      }
    }
  }


}

.feature{
  text-align: center;
  padding-bottom: 30px;
  @media (max-width: 767px){
    padding-bottom: 0px;
  }


  h2{
    font-family: "Lato Medium";
    font-weight: bold;
    padding-top: 14px;
    @media (max-width: 1199px) {
      font-size: 24px;
    }
  }
  img{
    width: 100%;
  }
  span.analytic{
    font-family: "Lato Medium";
    display: inline-block;
    font-weight: bold;
    padding-bottom: 1.2em;
  }
  span.prostobold{
    font-family: "Lato Medium";
    font-weight: bold;
    padding:0 5px;
  }
  .box-analytic{
    height: 28vw;
    width: 100%;
    display: flex;
    padding-bottom: 30px;
    align-items: flex-end;
    @media (max-width: 767px){
      height: 24em;
      padding-bottom: 0px;
      //padding-bottom: 30px;
    }

    &.analyz-1{
      background: url("/templates/seolit-mdl-index/assets/images/analyz-1.jpg") no-repeat top center;
      background-size: 100% auto;
    }
    &.analyz-2{
      background: url("/templates/seolit-mdl-index/assets/images/analyz-2.jpg") no-repeat top center;
      background-size: 100% auto;
    }
    &.analyz-3{
      background: url("/templates/seolit-mdl-index/assets/images/analyz-3.jpg") no-repeat top center;
      background-size: 100% auto;
    }
    &.analyz-4{
      background: url("/templates/seolit-mdl-index/assets/images/analyz-4.jpg") no-repeat top center;
      background-size: 100% auto;
    }

    &.preoritet-1{
      background: url("/templates/seolit-mdl-index/assets/images/preoritet-1.jpg") no-repeat top left;
      background-size: 100% auto;
    }
    &.preoritet-2{
      background: url("/templates/seolit-mdl-index/assets/images/preoritet-2.jpg") no-repeat top left;
      background-size: 100% auto;
    }
    &.preoritet-3{
      background: url("/templates/seolit-mdl-index/assets/images/preoritet-3.jpg") no-repeat top left;
      background-size: 100% auto;
    }
    &.preoritet-4{
      background: url("/templates/seolit-mdl-index/assets/images/preoritet-4.jpg") no-repeat top left;
      background-size: 100% auto;
    }

  }

  .box-analytic-st{
    height: 20vw;
    width: 100%;
    display: flex;
    padding-bottom: 0px;
    align-items: center;
    @media (max-width: 767px){
      height: 16em;
      margin-bottom: 1px;

    }
    &.non-standart-1 {
      background: url("/templates/seolit-mdl-index/assets/images/non-standart-001.jpg") no-repeat top left;
    }
    &.non-standart-2{
      background: url("/templates/seolit-mdl-index/assets/images/non-standart-002.jpg") no-repeat top center;
      background-size: 100% auto;
    }
    &.non-standart-3{
      background: url("/templates/seolit-mdl-index/assets/images/non-standart-003.jpg") no-repeat top center;
      background-size: 100% auto;
    }
    &.non-standart-4{
      background: url("/templates/seolit-mdl-index/assets/images/non-standart-004.jpg") no-repeat top center;
      background-size: 100% auto;
    }
  }

  .frame-annatation{
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: rgba(255 ,255, 255, 0.85);
  }

  .frame-annatation-st{
    width: 100%;
    min-height: 95px;
    align-items: center;
    display: flex;
    justify-content: center;
    background-color: rgba(255 ,255, 255, 0.85);
  }

}
.textGradient{
  color:$gradientColor1;
  background: -webkit-linear-gradient(180deg, #130B76, #130B76, #005CDB, #25064A, #25064A);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.spider-logo{
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 48em) {
  .desktop-only{
    display: none;
  }
}

.weapon.left:not(:last-child),.weapon.right:not(:last-child){
  padding-bottom: 1.5rem;
}


#secondSection,#fourthSection{
  padding-bottom: 5rem;
}
#thirdSection{
  padding-bottom: 1rem;
  .section-header{
    padding-top: 1em;
  }
}


#sixSection{
  .action__btn_white{
    display: inline-block;
    max-width: 90%;
    background: rgba(255,255,255,0.2);
    padding: .7em 3.5em;
    border-radius: 30px;
    text-decoration: none;
    color: white;
    transition: all .2s;
    border: 2px solid white;
    text-transform: uppercase;
    &:hover{
      background: white;
      color: $gradientColor2;
    }
  }
  .pricecard{
    background: $priceBG;
    //padding: 3rem;
    margin-bottom: 100px;
    border-radius: 20px;
    color:$white;
    padding:2rem 0;
    h2{
      font-weight: normal;
      padding-top: 0;
    }
    .leftPrice{
      border-right:1px solid white;
    }
    .price{
      font-size: 2rem;
      //font-weight: lighter;
    }
    sup{
      font-size: 1rem;
    }
    .month{
      padding-top: .3rem;
      padding-bottom: 1.5rem;
      color: rgba(255,255,255,0.6);
    }
    ul{
      //text-align: left;
      list-style: none;
      li{
        padding: 1rem;
      }
    }
    .promo{
      padding-top: 2rem;
      font-size: 2.5rem;
      font-weight: bold;
    }
  }
}

.weapon1{

}