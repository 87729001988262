@import "banner";
@import "reset";
@import "variables";
@import "typography";
@import "menu";
@import "bubbles";
@import "weapons";
@import "spider";
//@import "flexboxgrid";
@import "mockups";
@import "footer";
@import "popup";
@import "posting";
@import "simple";
@import "offering";
@import "schedule";
@import "analytics";
@import "aboveall";

@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i&display=swap&subset=cyrillic,cyrillic-ext,latin-ext');
@import url('https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i&display=swap&subset=cyrillic,cyrillic-ext,latin-ext');

body{
  overflow-x: hidden;
  position: relative;
  max-width: 100vw;
}

.notAbsoluteInFlex{
  width:100%;
  height:100vh;
  position: absolute;
  top:0;
  left: 0;
}

.ie{
  #bubbles {
    display: none;
  }
  width: 12vmin;
  height: 12vmin;
}

.divider {
  height: 100px;
  background: fixed url("../images/mobile.jpg");
  background-size: cover;
  @media(min-width: 800px){
    background: fixed url("../images/desktop.jpg");
  }
  &:after{
    content: "";
    display: block;
    height: 100%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.65) 0%, transparent 33%, transparent 69%, rgba(0, 0, 0, 0.65) 100%);
  }
}

.sectionWrapper{
  background: white;
}
#secondSection,#thirdSection,#fourthSection,#fifthSection,#sixSection,#lastSection,footer {
  width: 100%;
  margin:0 auto;
  //max-width: 1200px;
}
#lastSection, #thirdSection{
  background: #F2F2F2;
}
#lastSection{
  background: #DEDFE1;
  padding-top: 50px;
  padding-bottom: 50px;
  .action__btn_white{
    display: inline-block;
    max-width: 90%;
    background: linear-gradient(to top,#25064a,#005cdb);
    padding: .7em 2.2em;
    border-radius: 30px;
    text-decoration: none;
    color: white;
    transition: all .2s;
    //border: 2px solid white;
    text-transform: uppercase;
    &:hover{
      box-shadow: 0 0 10px #0094FF;
    }
  }
}
.action{
  text-align: center;
  padding:2rem 0;
  .action__btn{
    background: linear-gradient(to top, #25064A, #005CDB);
    font-size: 1.1rem;
    display: inline-block;
    max-width:90%;
    //background: $accentColor;
    padding: .7em 2.2em;
    border-radius: 30px;
    text-decoration: none;
    color: $white;
    transition: all .2s;
    margin-bottom:5px;
    &:hover{
      background: linear-gradient(to top, #25064A, #005CDB);
      box-shadow: 0 0 10px #0094FF;
    }
  }
}
@media(max-width: 1199px) {
  .margin-row-null {
    margin-left: 0px;
    margin-right: 0px;
  }
}
#thirdSection{
  .device-container{
    padding: 0 10%;
    margin:0 auto;
    margin-bottom:24px;
  }
}
.full-hd-img{
  width: 100%;
}
.arroy{
  position: absolute;
  height: 0;
  width: 0;
  border: 2vw solid transparent;
  border-top: 2vw solid #2f7ff6;
  left: -2vw;
  z-index: 1;
  bottom: -4vw;
  @media (max-width: 767px){
    border: 5vw solid transparent;
    border-top: 5vw solid #ffffff;
    left: 46vw;
    z-index: 1;
    bottom: -10.3vw;
  }
}
.auto-parts-1{
  width: 5vw;
  bottom: -4vw;
  @media (max-width: 767px){
    width: 14vw;
  }
}
.pict-schema{
  padding-bottom: 7vw;
  picture {
    vertical-align: top;
  }
}
.auto-parts-2{
  width: 9vw;
  @media (max-width: 767px){
    width: 24vw;
  }
}
#start, #secondSection{
  .container-fluid{
    //height:100%;
  }
  .how-it-works{
    position: relative;
    height:100%;
    //padding:12px;
    border: 1px solid lightgrey;
    h3 {
      align-items: center;
      display: flex;
      font-size: 1.8vw;
      font-weight: 300;
      @media (max-width: 767px){
        font-size: 5vw;
        font-weight: 400;
      }
    }
    img{
      max-width: 100%;
      vertical-align: middle;
    }
    .helper {
      display: inline-block;
      height: 100%;
      vertical-align: middle;
    }
  }
}
.price-container{
  text-align: center;
  background: white;
  border: 1px solid #BBBBBB;
  border-radius: 20px;
  .price-header{
    margin-bottom: 2rem;
    background-image:     linear-gradient(to bottom, rgba(255, 255, 255, 0) 20%, rgba(255, 255, 255, 0.6) 50%, rgba(255, 255, 255, 0) 75%),
    linear-gradient(to left, #000529, #061267, #0018BF, #00F0FF, #0018BF, #061267, #000529);
    margin-right: -15px;
    margin-left: -15px;
    border-radius: 20px 20px 0px 0px;
    h1{
      color: #000000;
      font-weight: 600;
      font-size: 2rem;
      padding-top: 0.72em;
      padding-bottom: 0.72em;
      text-shadow: white 0px 0px 2px, white 0px 0px 2px, white 0px 0px 2px, white 0px 0px 2px, white 0px 0px 2px,
      white 0px 0px 10px, white 0px 0px 10px;
      &:after{
        content:"";
        display:block;
        background: #0075FF;
        width: 100px;
        max-width: 30%;
        height: 2px;
        margin: 0.25em auto 0;
      }
    }
  }
  .to-globus{
    padding: 0px;
    align-items: center;
    display: flex;
    .project-title{
      font-weight: bold;
      font-size: 1.7vw;
      @media(max-width: 767px){
        font-size: 5vw;
      }
    }
  }
  .globus-price{
    display: flex;
    align-items: center;
    height: 16vw;
    background: url('/templates/seolit-mdl-index/assets/images/globus-price.png') no-repeat center center;
    background-size: 80% auto;
    @media(max-width: 767px){
      height: 41vw;
    }
    .cost-project{
      font-family: "Lato Medium";
      font-size: 3.5vw;
      margin-top: 0.5vw;
      font-weight: 700;
      color: #003FB9;
      text-shadow: #fff 0px 0px 5px, #fff 0px 0px 5px, #fff 0px 0px 5px, #fff 0px 0px 10px, #fff 0px 0px 10px, #fff 0px 0px 10px;
      @media(max-width: 767px){
        font-size: 9.5vw;
      }
      &::after{
        margin-left: 5px;
        font-family: 'icomoon';
        font-size: 2.8vw;
        content: '\f158';
        @media(max-width: 767px){
          font-size: 7.8vw;
        }
      }
    }
  }
  .icon-circle{
    margin-bottom: 1.0rem;
    justify-content: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    &::before{
      display: block;
      margin-right: 10px;
      content: '';
      height: 1px;
      width: 32px;
      background-color: #0094FF;
      @media(max-width: 767px){
        width: 25px;
      }
    }
    &::after{
      display: block;
      margin-left: 10px;
      content: '';
      height: 1px;
      width: 32px;
      background-color: #0094FF;
      @media(max-width: 767px){
        width: 25px;
      }
    }
  }
  .icon-price{
    border-radius: 100%;
    border:2px solid #97EAFF;
    box-shadow: 0 0 7px 2px #0094FF;
    display:inline-block;
    width:3rem;
    height:3rem;
    line-height: 2.85rem;
    text-align: center;
    font-size: 1.6rem;
    background: radial-gradient(circle,   rgba(16, 0, 201, 0.4) -20%, rgba(0, 38, 111, 1.0) 75%, rgba(0, 21, 61, 1.0) 100%);
    text-shadow: #0094FF 0px 0px 5px, #0094FF 0px 0px 5px;
    text-decoration: none;
    color: #9AEEFF;
    transition: all .2s;
    @media(min-width: 1600px){
      width:3vw;
      height:3vw;
      font-size: 1.6vw;
      line-height: 2.8vw;
    }
    &.ss:before{
      content: '\f110';
    }
    &.Rss:before{
      content: '\f09e';
    }
    &.delegir:before{
      content: '\e906';
    }
    &.schedule:before{
      content: '\e907';
    }
    &.watermark:before{
      content: '\e908';
    }
    &.filtr:before{
      content: '\e905';
    }
    &.template:before{
      content: '\e909';
    }
    &.trash:before{
      content: '\e90a';
    }
  }
  .dop-account-cost{
    font-family: 'Lato Medium';
    font-weight: bold;
    @media(min-width: 768px){
      font-size: 1rem;
    }
    span{
      font-size: 2vw;
      color: #003fb9;
      text-shadow: #fff 0 0 5px, #fff 0 0 5px, #fff 0 0 5px, #fff 0 0 10px, #fff 0 0 10px, #fff 0 0 10px;
      @media(max-width: 767px){
        font-size: 24px;
      }
    }
  }
  .bottom-height-FullHD {
    @media(min-width: 1600px){
      height: 8vw;
    }
  }
}
.green-button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 220px;
  min-width: 220px;
  height: 50px;
  border: none;
  border-radius: 5px;
  background: $green;
  text-transform: uppercase;
  font-size: 21px;
  font-weight: 700;
  color: $white;
  transition: all 0.1s linear 0s;

  &:hover, &:focus {
    color: $white;
    text-decoration: none;
    background: #019807;
  }
  &:active {
    outline: none;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.5) inset;
    background: #018D06;
    color: gray;
  }
}
.section__title {
  font-size: 44px;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 40px;
  padding-top: 0;
}

.b24__wrapper {
  background: linear-gradient(180deg, #F1FEFE 0%, #B9E5F1 78.65%);
}

.b24__text {
  font-size: 18px;
  line-height: 22px;
  max-width: 800px;
  margin: 30px auto 50px;

  & > b {
    margin: 0 5px;
  }
}

@media(max-width: 767px) {
  .green-button {
    width: 50vw;
    min-width: 50vw;
    height: 30px;
    font-size: 15px;
    margin: 0 auto;
  }
  .section__title {
    font-size: 22px;
    padding: 0;
    margin-bottom: 0;
  }
  .b24__text {
    font-size: 13px;
    line-height: 15px;
  }
}