.analytics {
  padding: 45px 60px;
  text-align: center;
}
.analytics__list {
  list-style: none;
  display: flex;
  justify-content: space-between;
  margin: 40px 0 30px;
}
.analytics__list-item {
  position: relative;
  padding-left: 35px;

  &.analytics__list-item--posts:before {
    background: #3BBFE1;
  }
  &.analytics__list-item--likes:before {
    background: #FFD900;
  }
  &.analytics__list-item--reposts:before {
    background: #7EBE6D;
  }
  &.analytics__list-item--comments:before {
    background: #EE9CE5;
  }
  &.analytics__list-item--mistakes:before {
    background: #FF8282;
  }
}
.analytics__list-item::before {
  position: absolute;
  content: '';
  width: 18px;
  height: 18px;
  background: gray;
  left: 0;
  top: 2px;
}
.analytics__hidden-text {
  position: relative;
  display: inline-block;
  color: #707B91;
  margin: 30px auto 50px;
  width: fit-content;
}
.analytics__hidden-text::before {
  position: absolute;
  content: "";
  width: 27px;
  height: 27px;
  background: no-repeat url("../images/exclamation-icon.svg");
  top: -5px;
  left: -50px;
}
.analytics__column-text {
  padding: 0 60px 0 0;
  line-height: 25px;
}

@media (max-width: 767px) {
  .analytics {
    padding: 30px 20px;
  }
  .analytics__list {
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 15px;
  }
  .analytics__hidden-text {
    padding-left: 30px;

    &::before {
      left: 0;
      top: 0;
      width: 18px;
      height: 18px;
      padding-left: 30px;
      background-size: 18px auto;
    }
  }
  .analytics__column-text {
    font-size: 13px;
    line-height: 15px;
    padding: 0;
  }
  .offering__row--analytics {
    &.offering__column {
      min-width: 200px;
    }
  }
}
@media (min-width: 1700px) {
  .analytics__hidden-text::before {
    top: 0;
  }
}