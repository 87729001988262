@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i&display=swap&subset=cyrillic,cyrillic-ext,latin-ext");
@import url("https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i&display=swap&subset=cyrillic,cyrillic-ext,latin-ext");
#header-carousel {
  background: url("../images/circle-gradient.png") no-repeat -58px -200px;
  background-size: auto 150%;
  font-family: "Lato Medium"; }

.banner:hover {
  text-decoration: none;
  cursor: pointer; }

.banner {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-wrap: wrap;
  height: 80vh;
  width: 100%; }
  @media (max-width: 767px) {
    .banner {
      margin-top: 0px;
      height: auto;
      justify-content: center;
      min-height: 103vh;
      background-size: auto 100%; } }
  @media (min-width: 768px) and (max-width: 1528px) {
    .banner {
      justify-content: center;
      min-height: 103vh; } }
  @media (min-width: 992px) and (max-width: 1200px) {
    .banner {
      align-items: flex-end; } }
  .banner .glob {
    position: absolute;
    z-index: 1;
    width: 50vw;
    top: 6%; }

@keyframes pulse {
  0% {
    filter: brightness(100%) drop-shadow(0 12px 10px #ffffff); }
  80% {
    filter: brightness(100%) drop-shadow(0 12px 10px #ffffff); }
  90% {
    filter: brightness(150%) drop-shadow(0 12px 50px #ffffff); }
  100% {
    filter: brightness(100%) drop-shadow(0 12px 10px #ffffff); } }
    @media (min-width: 320px) and (max-width: 767px) {
      .banner .glob {
        width: 118px;
        top: 10%; } }
    @media (min-width: 768px) and (max-width: 1023px) {
      .banner .glob {
        width: 25vw;
        top: 10%; } }
    @media (min-width: 1024px) {
      .banner .glob {
        width: 300px;
        top: 100px; } }
    @media (min-width: 1024px) and (min-height: 1200px) {
      .banner .glob {
        width: 40vw;
        top: 3.5%; } }
    .banner .glob img {
      width: 100%; }
    .banner .glob p {
      font-size: 21px;
      font-weight: 700;
      text-align: center;
      color: #054D90;
      margin-top: 20px; }
  .banner .mobil-banner, .banner img.mobil-banner {
    width: 100%; }
    @media (min-width: 768px) {
      .banner .mobil-banner, .banner img.mobil-banner {
        display: none; } }
  .banner .animation-ss {
    top: 50%;
    right: 50%;
    height: 10px;
    width: 10px;
    position: absolute;
    z-index: 0;
    animation-duration: 3.5s;
    animation-iteration-count: infinite;
    animation-timing-function: cubic-bezier(0, 0.3, 0.8, 0.1); }
    .banner .animation-ss.circle-tw {
      animation-name: fly-tw; }

@keyframes fly-tw {
  from {
    top: 50%;
    left: 50%;
    height: 10px;
    width: 10px;
    opacity: 1; }
  to {
    top: 80%;
    left: -10%;
    height: 60px;
    width: 60px;
    opacity: 0; } }
    @media (max-width: 767px), (min-width: 768px) and (max-width: 819px) and (max-height: 375px) {
      @keyframes fly-tw {
        from {
          top: 50%;
          left: 50%;
          height: 0px;
          width: 0px;
          opacity: 1; }
        to {
          top: 80%;
          left: -15%;
          height: 40px;
          width: 40px;
          opacity: 0; } } }
    .banner .animation-ss.circle-tw-2 {
      animation-name: fly-tw-2;
      animation-delay: 1.75s; }

@keyframes fly-tw-2 {
  from {
    top: 50%;
    left: 50%;
    height: 10px;
    width: 10px;
    opacity: 1; }
  to {
    top: -10%;
    left: 15%;
    height: 60px;
    width: 60px;
    opacity: 0; } }
    @media (max-width: 767px), (min-width: 768px) and (max-width: 819px) and (max-height: 375px) {
      @keyframes fly-tw-2 {
        from {
          top: 50%;
          left: 50%;
          height: 0px;
          width: 0px;
          opacity: 1; }
        to {
          top: -10%;
          left: 15%;
          height: 40px;
          width: 40px;
          opacity: 0; } } }
    .banner .animation-ss.circle-ok {
      animation-name: fly-ok;
      animation-delay: 0.4s; }

@keyframes fly-ok {
  from {
    top: 50%;
    left: 50%;
    height: 10px;
    width: 10px;
    opacity: 1; }
  to {
    top: 15%;
    left: -10%;
    height: 75px;
    width: 75px;
    opacity: 0; } }
    @media (max-width: 767px), (min-width: 768px) and (max-width: 819px) and (max-height: 375px) {
      @keyframes fly-ok {
        from {
          top: 50%;
          left: 50%;
          height: 0px;
          width: 0px;
          opacity: 1; }
        to {
          top: 15%;
          left: -15%;
          height: 40px;
          width: 40px;
          opacity: 0; } } }
    .banner .animation-ss.circle-ok-2 {
      animation-name: fly-ok-2;
      animation-delay: 1.5s; }

@keyframes fly-ok-2 {
  from {
    top: 50%;
    left: 50%;
    height: 10px;
    width: 10px;
    opacity: 1; }
  to {
    top: 80%;
    left: -10%;
    height: 75px;
    width: 75px;
    opacity: 0; } }
    @media (max-width: 767px), (min-width: 768px) and (max-width: 819px) and (max-height: 375px) {
      @keyframes fly-ok-2 {
        from {
          top: 50%;
          left: 50%;
          height: 0px;
          width: 0px;
          opacity: 1; }
        to {
          top: 80%;
          left: -15%;
          height: 40px;
          width: 40px;
          opacity: 0; } } }
    .banner .animation-ss.circle-vk {
      animation-name: fly-vk;
      animation-delay: 0.7s; }

@keyframes fly-vk {
  from {
    top: 50%;
    left: 50%;
    height: 10px;
    width: 10px;
    opacity: 1; }
  to {
    top: -10%;
    left: 15%;
    height: 60px;
    width: 60px;
    opacity: 0; } }
    @media (max-width: 767px), (min-width: 768px) and (max-width: 819px) and (max-height: 375px) {
      @keyframes fly-vk {
        from {
          top: 50%;
          left: 50%;
          height: 0px;
          width: 0px;
          opacity: 1; }
        to {
          top: -10%;
          left: 15%;
          height: 40px;
          width: 40px;
          opacity: 0; } } }
    .banner .animation-ss.circle-vk-2 {
      animation-name: fly-vk-2;
      animation-delay: 2.1s; }

@keyframes fly-vk-2 {
  from {
    top: 50%;
    left: 50%;
    height: 10px;
    width: 10px;
    opacity: 1; }
  to {
    top: 50%;
    left: -10%;
    height: 100px;
    width: 100px;
    opacity: 0; } }
    @media (max-width: 767px), (min-width: 768px) and (max-width: 819px) and (max-height: 375px) {
      @keyframes fly-vk-2 {
        from {
          top: 50%;
          left: 50%;
          height: 0px;
          width: 0px;
          opacity: 1; }
        to {
          top: 50%;
          left: -15%;
          height: 60px;
          width: 60px;
          opacity: 0; } } }
    .banner .animation-ss.circle-tumblr {
      animation-name: fly-tumblr;
      animation-delay: 0.9s; }

@keyframes fly-tumblr {
  from {
    top: 50%;
    left: 50%;
    height: 10px;
    width: 10px;
    opacity: 1; }
  to {
    top: 50%;
    left: -10%;
    height: 60px;
    width: 60px;
    opacity: 0; } }
    @media (max-width: 767px), (min-width: 768px) and (max-width: 819px) and (max-height: 375px) {
      @keyframes fly-tumblr {
        from {
          top: 50%;
          left: 50%;
          height: 0px;
          width: 0px;
          opacity: 1; }
        to {
          top: 50%;
          left: -15%;
          height: 40px;
          width: 40px;
          opacity: 0; } } }
    .banner .animation-ss.circle-tumblr-2 {
      animation-name: fly-tumblr-2;
      animation-delay: 3.1s; }

@keyframes fly-tumblr-2 {
  from {
    top: 50%;
    right: 50%;
    height: 10px;
    width: 10px;
    opacity: 1; }
  to {
    top: 50%;
    right: -10%;
    height: 60px;
    width: 60px;
    opacity: 0; } }
    @media (max-width: 767px), (min-width: 768px) and (max-width: 819px) and (max-height: 375px) {
      @keyframes fly-tumblr-2 {
        from {
          top: 50%;
          right: 50%;
          height: 0px;
          width: 0px;
          opacity: 1; }
        to {
          top: 50%;
          right: -15%;
          height: 40px;
          width: 40px;
          opacity: 0; } } }
    .banner .animation-ss.circle-lj {
      animation-name: fly-lj;
      animation-delay: 0s; }

@keyframes fly-lj {
  from {
    top: 50%;
    left: 50%;
    height: 10px;
    width: 10px;
    opacity: 1; }
  to {
    top: 50%;
    left: -10%;
    height: 60px;
    width: 60px;
    opacity: 0; } }
    @media (max-width: 767px), (min-width: 768px) and (max-width: 819px) and (max-height: 375px) {
      @keyframes fly-lj {
        from {
          top: 50%;
          left: 50%;
          height: 0px;
          width: 0px;
          opacity: 1; }
        to {
          top: 50%;
          left: -15%;
          height: 40px;
          width: 40px;
          opacity: 0; } } }
    .banner .animation-ss.circle-lj-2 {
      animation-name: fly-lj-2;
      animation-delay: 2.6s; }

@keyframes fly-lj-2 {
  from {
    top: 50%;
    left: 50%;
    height: 10px;
    width: 10px;
    opacity: 1; }
  to {
    top: 15%;
    left: -10%;
    height: 60px;
    width: 60px;
    opacity: 0; } }
    @media (max-width: 767px), (min-width: 768px) and (max-width: 819px) and (max-height: 375px) {
      @keyframes fly-lj-2 {
        from {
          top: 50%;
          left: 50%;
          height: 0px;
          width: 0px;
          opacity: 1; }
        to {
          top: 15%;
          left: -15%;
          height: 40px;
          width: 40px;
          opacity: 0; } } }
    .banner .animation-ss.circle-in {
      animation-name: fly-in;
      animation-delay: 1.5s; }

@keyframes fly-in {
  from {
    top: 50%;
    left: 50%;
    height: 10px;
    width: 10px;
    opacity: 1; }
  to {
    top: 15%;
    left: -10%;
    height: 60px;
    width: 60px;
    opacity: 0; } }
    @media (max-width: 767px), (min-width: 768px) and (max-width: 819px) and (max-height: 375px) {
      @keyframes fly-in {
        from {
          top: 50%;
          left: 50%;
          height: 0px;
          width: 0px;
          opacity: 1; }
        to {
          top: 15%;
          left: -15%;
          height: 40px;
          width: 40px;
          opacity: 0; } } }
    .banner .animation-ss.circle-in-2 {
      animation-name: fly-in-2;
      animation-delay: 2.8s; }

@keyframes fly-in-2 {
  from {
    top: 50%;
    left: 50%;
    height: 10px;
    width: 10px;
    opacity: 1; }
  to {
    top: -10%;
    left: 15%;
    height: 90px;
    width: 90px;
    opacity: 0; } }
    @media (max-width: 767px), (min-width: 768px) and (max-width: 819px) and (max-height: 375px) {
      @keyframes fly-in-2 {
        from {
          top: 50%;
          left: 50%;
          height: 0px;
          width: 0px;
          opacity: 1; }
        to {
          top: -10%;
          left: 15%;
          height: 60px;
          width: 60px;
          opacity: 0; } } }
    .banner .animation-ss.circle-pint {
      animation-name: fly-pint; }

@keyframes fly-pint {
  from {
    top: 50%;
    right: 50%;
    height: 10px;
    width: 10px;
    opacity: 1; }
  to {
    top: 80%;
    right: -10%;
    height: 60px;
    width: 60px;
    opacity: 0; } }
    @media (max-width: 767px), (min-width: 768px) and (max-width: 819px) and (max-height: 375px) {
      @keyframes fly-pint {
        from {
          top: 50%;
          right: 50%;
          height: 0px;
          width: 0px;
          opacity: 1; }
        to {
          top: 80%;
          right: -15%;
          height: 40px;
          width: 40px;
          opacity: 0; } } }
    .banner .animation-ss.circle-pint-2 {
      animation-name: fly-pint-2;
      animation-delay: 1.75s; }

@keyframes fly-pint-2 {
  from {
    top: 50%;
    right: 50%;
    height: 10px;
    width: 10px;
    opacity: 1; }
  to {
    top: -10%;
    right: 15%;
    height: 60px;
    width: 60px;
    opacity: 0; } }
    @media (max-width: 767px), (min-width: 768px) and (max-width: 819px) and (max-height: 375px) {
      @keyframes fly-pint-2 {
        from {
          top: 50%;
          right: 50%;
          height: 0px;
          width: 0px;
          opacity: 1; }
        to {
          top: -10%;
          right: 15%;
          height: 40px;
          width: 40px;
          opacity: 0; } } }
    .banner .animation-ss.circle-tg {
      animation-name: fly-tg;
      animation-delay: 0.4s; }

@keyframes fly-tg {
  from {
    top: 50%;
    right: 50%;
    height: 10px;
    width: 10px;
    opacity: 1; }
  to {
    top: 15%;
    right: -10%;
    height: 75px;
    width: 75px;
    opacity: 0; } }
    @media (max-width: 767px), (min-width: 768px) and (max-width: 819px) and (max-height: 375px) {
      @keyframes fly-tg {
        from {
          top: 50%;
          right: 50%;
          height: 0px;
          width: 0px;
          opacity: 1; }
        to {
          top: 15%;
          right: -15%;
          height: 40px;
          width: 40px;
          opacity: 0; } } }
    .banner .animation-ss.circle-tg-2 {
      animation-name: fly-tg-2;
      animation-delay: 1.5s; }

@keyframes fly-tg-2 {
  from {
    top: 50%;
    right: 50%;
    height: 10px;
    width: 10px;
    opacity: 1; }
  to {
    top: 80%;
    right: -10%;
    height: 75px;
    width: 75px;
    opacity: 0; } }
    @media (max-width: 767px), (min-width: 768px) and (max-width: 819px) and (max-height: 375px) {
      @keyframes fly-tg-2 {
        from {
          top: 50%;
          right: 50%;
          height: 0px;
          width: 0px;
          opacity: 1; }
        to {
          top: 80%;
          right: -15%;
          height: 40px;
          width: 40px;
          opacity: 0; } } }
    .banner .animation-ss.circle-fb {
      animation-name: fly-fb;
      animation-delay: 0.7s; }

@keyframes fly-fb {
  from {
    top: 50%;
    right: 50%;
    height: 10px;
    width: 10px;
    opacity: 1; }
  to {
    top: -10%;
    right: 15%;
    height: 90px;
    width: 90px;
    opacity: 0; } }
    @media (max-width: 767px), (min-width: 768px) and (max-width: 819px) and (max-height: 375px) {
      @keyframes fly-fb {
        from {
          top: 50%;
          right: 50%;
          height: 0px;
          width: 0px;
          opacity: 1; }
        to {
          top: -10%;
          right: 15%;
          height: 60px;
          width: 60px;
          opacity: 0; } } }
    .banner .animation-ss.circle-fb-2 {
      animation-name: fly-fb-2;
      animation-delay: 2.1s; }

@keyframes fly-fb-2 {
  from {
    top: 50%;
    right: 50%;
    height: 10px;
    width: 10px;
    opacity: 1; }
  to {
    top: 50%;
    right: -10%;
    height: 60px;
    width: 60px;
    opacity: 0; } }
    @media (max-width: 767px), (min-width: 768px) and (max-width: 819px) and (max-height: 375px) {
      @keyframes fly-fb-2 {
        from {
          top: 50%;
          right: 50%;
          height: 0px;
          width: 0px;
          opacity: 1; }
        to {
          top: 50%;
          right: -15%;
          height: 40px;
          width: 40px;
          opacity: 0; } } }
    .banner .animation-ss.circle-inst {
      animation-name: fly-inst;
      animation-delay: 0.9s; }

@keyframes fly-inst {
  from {
    top: 50%;
    right: 50%;
    height: 10px;
    width: 10px;
    opacity: 1; }
  to {
    top: 50%;
    right: -10%;
    height: 100px;
    width: 100px;
    opacity: 0; } }
    @media (max-width: 767px), (min-width: 768px) and (max-width: 819px) and (max-height: 375px) {
      @keyframes fly-inst {
        from {
          top: 50%;
          right: 50%;
          height: 0px;
          width: 0px;
          opacity: 1; }
        to {
          top: 50%;
          right: -15%;
          height: 60px;
          width: 60px;
          opacity: 0; } } }
    .banner .animation-ss.circle-inst-2 {
      animation-name: fly-inst-2;
      animation-delay: 2.6s; }

@keyframes fly-inst-2 {
  from {
    top: 50%;
    right: 50%;
    height: 10px;
    width: 10px;
    opacity: 1; }
  to {
    top: 15%;
    right: -10%;
    height: 60px;
    width: 60px;
    opacity: 0; } }
    @media (max-width: 767px), (min-width: 768px) and (max-width: 819px) and (max-height: 375px) {
      @keyframes fly-inst-2 {
        from {
          top: 50%;
          right: 50%;
          height: 0px;
          width: 0px;
          opacity: 1; }
        to {
          top: 15%;
          right: -15%;
          height: 40px;
          width: 40px;
          opacity: 0; } } }
    .banner .animation-ss.circle-mail {
      animation-name: fly-mail;
      animation-delay: 1.5s; }

@keyframes fly-mail {
  from {
    top: 50%;
    right: 50%;
    height: 10px;
    width: 10px;
    opacity: 1; }
  to {
    top: 15%;
    right: -10%;
    height: 60px;
    width: 60px;
    opacity: 0; } }
    @media (max-width: 767px), (min-width: 768px) and (max-width: 819px) and (max-height: 375px) {
      @keyframes fly-mail {
        from {
          top: 50%;
          right: 50%;
          height: 0px;
          width: 0px;
          opacity: 1; }
        to {
          top: 15%;
          right: -15%;
          height: 40px;
          width: 40px;
          opacity: 0; } } }
    .banner .animation-ss.circle-mail-2 {
      animation-name: fly-mail-2;
      animation-delay: 2.8s; }

@keyframes fly-mail-2 {
  from {
    top: 50%;
    right: 50%;
    height: 10px;
    width: 10px;
    opacity: 1; }
  to {
    top: -10%;
    right: 15%;
    height: 60px;
    width: 60px;
    opacity: 0; } }
    @media (max-width: 767px), (min-width: 768px) and (max-width: 819px) and (max-height: 375px) {
      @keyframes fly-mail-2 {
        from {
          top: 50%;
          right: 50%;
          height: 0px;
          width: 0px;
          opacity: 1; }
        to {
          top: -10%;
          right: 15%;
          height: 40px;
          width: 40px;
          opacity: 0; } } }

.banner__text-container {
  padding-top: 40px;
  margin: 0 auto; }

.banner__slogans-list {
  display: flex;
  justify-content: space-between;
  list-style: none;
  text-decoration: underline;
  font-size: 18px;
  color: #054D90; }

.banner__slogan-item a {
  color: #054D90;
  transition: all 0.15s linear 0s; }

.banner__slogan-item a:hover,
.banner__slogan-item a:active,
.banner__slogan-item a:focus {
  color: #02B509; }

.banner__text-title {
  padding: 0;
  font-size: 60px;
  text-transform: uppercase; }

.banner__text {
  font-size: 30px;
  line-height: 1; }

.banner__text-wrapper--mobile {
  display: none; }

.banner__price-container {
  display: flex;
  align-items: center;
  margin-bottom: 30px; }

.banner__price {
  display: flex;
  align-items: center;
  font-size: 53px;
  color: #054D90;
  margin-left: 10px; }
  .banner__price span {
    font-family: "Segoe UI";
    font-size: 15px; }
  .banner__price .price {
    font-size: 27px; }

.banner__currency-container {
  display: flex;
  flex-direction: column;
  font-size: 21px;
  color: #054D90;
  margin-left: 10px; }
  .banner__currency-container span:first-child {
    border-bottom: 1px dashed blue; }

.banner__description {
  font-size: 21px;
  color: #054D90;
  line-height: 25px; }

.banner__button-container {
  display: flex;
  gap: 30px;
  margin-bottom: 30px;
  margin-top: 60px; }

.banner__small-description {
  font-size: 16px;
  color: gray;
  font-style: italic; }

.banner__free {
  font-size: 18px;
  font-style: italic;
  margin-bottom: 2.5rem; }
  .banner__free u {
    margin-left: 5px; }

.banner__mobile-logo {
  display: none; }

.banner__socials {
  display: flex;
  margin-top: 70px;
  padding: 50px 14px;
  border-radius: 20px;
  max-height: 440px; }

.banner__socials-list p {
  transform: rotate(-90deg);
  transform-origin: left top;
  font-family: "Lato Medium";
  font-size: 11px; }

.banner__socials-ul {
  display: flex;
  flex-direction: column;
  gap: 5px;
  list-style: none; }
  .banner__socials-ul > li {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 21px;
    height: 21px;
    border-radius: 50%;
    font-size: 12px; }

.banner__socials-icon {
  height: 10px;
  width: 12px; }

.banner__socials--mobile {
  display: none; }

@media (max-width: 1052px) {
  .banner__socials {
    display: none; }
  #header-carousel {
    background: url("../images/circle-gradient.png") no-repeat -300px -300px; } }

@media (max-width: 767px) {
  .banner,
  .banner__slogans-list,
  .banner__small-description,
  .animation-ss {
    display: none; }
  .banner__text-wrapper {
    display: none; }
  .banner__text-wrapper--mobile {
    display: grid;
    grid-template-columns: 117px 1fr;
    grid-template-rows: 1fr;
    gap: 0 20px;
    margin-bottom: 30px;
    padding-top: 20px; }
  .banner__mobile-logo {
    display: inline-block;
    width: 118px;
    grid-row-start: 1;
    grid-row-end: 3;
    margin: 0; }
  .banner__text-title {
    font-size: 34px;
    grid-row-start: 1;
    grid-row-end: 2;
    margin: 0 0 20px 0;
    text-align: left; }
  .banner__text-container {
    padding-top: 0; }
  .banner__text {
    font-size: 15px;
    margin: 0 auto 0 0; }
  .banner__text-second {
    display: flex;
    flex-direction: column;
    justify-content: center; }
  .banner__price-container {
    display: flex;
    flex-direction: column;
    margin: 0; }
  .banner__price {
    margin: 0 auto 0 0;
    align-items: first baseline; }
  .banner__description {
    font-size: 15px;
    line-height: 18px;
    color: #054D90;
    margin-bottom: 15px; }
  .banner__button-container {
    display: flex;
    gap: 30px;
    margin: 30px auto 15px; }
  .banner__free {
    font-size: 13px;
    color: #707B91;
    text-align: center; }
  .banner__socials--mobile {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    margin-bottom: 20px; }
  .banner__socials-ul {
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    margin: 0;
    padding-right: 10px; }
  .banner__socials-list {
    display: grid;
    grid-template-columns: 54px 1fr; }
    .banner__socials-list p {
      transform: none; }
  #header-carousel {
    background: url("../images/circle-gradient.png") no-repeat -100px -125px;
    background-size: auto 100%;
    padding: 0 15px; } }

.owl-carousel.owl-loaded .owl-item img.page-banner {
  width: 25vw; }
  @media (max-width: 767px) {
    .owl-carousel.owl-loaded .owl-item img.page-banner {
      width: 67vw; } }

@media (max-width: 767px) {
  .owl-carousel.owl-loaded {
    padding: 0px; } }

.owl-carousel .under-banner {
  padding: 0px; }
  @media (max-width: 1199px) {
    .owl-carousel .under-banner {
      margin: 0px; } }
  .owl-carousel .under-banner .gap {
    padding: 0px 12px; }
    @media (max-width: 767px) {
      .owl-carousel .under-banner .gap {
        padding: 0px; } }
    .owl-carousel .under-banner .gap .banner-produkt {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      height: 13vw;
      margin-bottom: 17px; }
      @media (max-width: 1199px) {
        .owl-carousel .under-banner .gap .banner-produkt {
          height: 15vw; } }
      @media (max-width: 767px) {
        .owl-carousel .under-banner .gap .banner-produkt {
          height: 50vw; } }
      .owl-carousel .under-banner .gap .banner-produkt.wood {
        background: url("image_content/wood.jpg") no-repeat center center;
        background-size: 100% 100%; }
      .owl-carousel .under-banner .gap .banner-produkt.stone {
        background: url("image_content/stone.jpg") no-repeat center center;
        background-size: 100% 100%; }
      .owl-carousel .under-banner .gap .banner-produkt.herringbone {
        background: url("image_content/herringbone.jpg") no-repeat center center;
        background-size: 100% 100%; }

.owl-carousel .more {
  font-family: "FuturaMediumC";
  width: 168px;
  color: white;
  text-transform: uppercase;
  text-align: center;
  font-weight: 400;
  display: block;
  margin-bottom: 60px;
  margin-top: 40px;
  padding: 8px 20px 8px 20px;
  background-color: #F27F4C;
  border-radius: 10px; }
  .owl-carousel .more:hover {
    background-color: #F3763F; }
  @media (max-width: 991px) {
    .owl-carousel .more {
      margin-bottom: 20px; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .owl-carousel .more {
      margin-bottom: 0; } }
  @media (min-width: 992px) and (max-width: 1200px) {
    .owl-carousel .more {
      margin-bottom: 100px; } }
  @media (max-width: 767px) {
    .owl-carousel .more {
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 8vw;
      width: 160px;
      font-family: "FuturaBookC";
      color: white;
      font-weight: lighter; } }

.owl-carousel .container-banner {
  padding: 0px; }
  @media (max-width: 767px) {
    .owl-carousel .container-banner .mobil-page-1 {
      display: flex;
      justify-content: center; } }
  .owl-carousel .container-banner .annotation {
    text-align: center;
    padding-bottom: 10vw; }
    @media (max-width: 419px) {
      .owl-carousel .container-banner .annotation {
        padding-bottom: 30vw; } }
  @media (max-width: 767px) {
    .owl-carousel .container-banner {
      text-align: center; } }

.owl-carousel .banner-note {
  font-family: "FuturaDemiC";
  text-transform: uppercase;
  font-weight: 400;
  text-align: left;
  display: block;
  font-size: 4vw;
  color: #ffffff; }
  @media (min-width: 992px) and (max-width: 1200px) {
    .owl-carousel .banner-note {
      font-size: 40px; } }
  @media (min-width: 1440px) {
    .owl-carousel .banner-note {
      font-size: 57px; } }
  @media (max-width: 767px) {
    .owl-carousel .banner-note {
      font-size: 42px;
      margin-top: 40px;
      text-align: center; } }

.owl-carousel .banner-text-product {
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: 400;
  display: block;
  font-size: 16px;
  color: black;
  text-align: justify; }

.owl-carousel .banner-model {
  text-shadow: #000 0px 4px 6px;
  font-weight: 600;
  font-size: 2vw;
  color: #ffffff; }
  @media (min-width: 420px) and (max-width: 767px) {
    .owl-carousel .banner-model {
      font-size: 3vw;
      justify-content: center;
      color: #ffffff; } }
  @media (min-width: 768px) and (max-width: 1023px) {
    .owl-carousel .banner-model {
      font-size: 4vw;
      justify-content: center;
      color: #ffffff; } }
  @media (min-width: 1024px) and (max-width: 1099px) {
    .owl-carousel .banner-model {
      font-size: 3vw; } }
  @media (max-width: 419px) {
    .owl-carousel .banner-model {
      font-size: 5vw; } }

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box; }

.acc-instagram {
  background: #ffdc80;
  background: -moz-linear-gradient(45deg, #ffdc80 0%, #f77737 22%, #f56040 37%, #fd1d1d 52%, #fd1d1d 52%, #c13584 66%, #c13584 66%, #833ab4 78%, #405de6 100%);
  background: -webkit-linear-gradient(45deg, #ffdc80 0%, #f77737 22%, #f56040 37%, #fd1d1d 52%, #fd1d1d 52%, #c13584 66%, #c13584 66%, #833ab4 78%, #405de6 100%);
  background: linear-gradient(45deg, #ffdc80 0%, #f77737 22%, #f56040 37%, #fd1d1d 52%, #fd1d1d 52%, #c13584 66%, #c13584 66%, #833ab4 78%, #405de6 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffdc80', endColorstr='#405de6',GradientType=1 ); }

.acc-blogger {
  background: #f57c00 !important; }

.acc-facebook {
  background: #3b5998 !important; }

.acc-google-plus {
  background: #dd4b39 !important; }

.acc-linkedin {
  background: #0077b5 !important; }

.acc-livejournal {
  background: #004359 !important; }

.acc-my-world {
  background: #168de2 !important; }

.acc-odnoklassniki {
  background: #ed812b !important; }

.acc-paper-plane {
  background: #0088cc !important; }

.acc-pencil {
  background: #4AC948 !important; }

.acc-pinterest {
  background: #bd081c !important; }

.acc-reddit {
  background: #ff4500 !important; }

.acc-rss {
  background: #f26522 !important; }

.acc-tiktok {
  background: #101020 !important; }

.acc-tumblr {
  background: #001935 !important; }

.acc-twitter {
  background: #1da1f2 !important; }

.acc-vk, .acc-vk-market {
  background: #45668e !important; }

.acc-youtube {
  background: #cd201f !important; }

@font-face {
  font-family: 'icomoon';
  src: url("../fonts/icomoon.eot?2b14r8");
  src: url("../fonts/icomoon.eot?2b14r8#iefix") format("embedded-opentype"), url("../fonts/icomoon.ttf?2b14r8") format("truetype"), url("../fonts/icomoon.woff?2b14r8") format("woff"), url("../fonts/icomoon.svg?2b14r8#icomoon") format("svg");
  font-weight: normal;
  font-style: normal; }

.fa-livejournal,
.fa-mail-ru,
.fa-my-world,
.fa-vk-market,
.fa-yandex {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.fa-livejournal:before {
  content: "\1f300"; }

.fa-mail-ru:before {
  content: "\e901"; }

.fa-my-world:before {
  content: "\e900"; }

.fa-vk-market:before {
  content: "\e904"; }

.fa.fa-yandex:before {
  content: "\e902"; }

.fab.fa-tiktok:before {
  content: "\e07b"; }

html {
  overflow-x: hidden;
  font-family: 'Ubuntu', sans-serif;
  font-size: 14px; }

@media (min-width: 800px) {
  html {
    font-size: 16px; } }

.section-header {
  text-align: center; }

.gradient {
  font-size: 72px;
  color: #30cfd0;
  background: -webkit-linear-gradient(90deg, #30cfd0, #330867);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; }

.right {
  text-align: right; }

p {
  line-height: 18px;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem; }
  @media (min-width: 1600px) {
    p {
      font-size: 1.2vw;
      line-height: 1.3vw; } }

@media (min-width: 768px) and (max-width: 991px) {
  .size {
    white-space: nowrap;
    /* Запрещаем перенос строк */
    overflow: hidden;
    /* Обрезаем все, что не помещается в область */
    text-overflow: ellipsis;
    /* Добавляем многоточие */ } }

h1, h3 {
  font-family: "Lato Medium";
  padding-top: 2.2em;
  padding-bottom: 1.2em; }

h2 {
  padding-top: 2.2em; }
  @media (min-width: 1600px) {
    h2 {
      font-size: 2vw; } }

.section-header {
  color: #212121;
  font-weight: normal;
  font-size: 2rem;
  padding-top: 2.5em;
  padding-bottom: 0.8em; }
  @media (max-width: 767px) {
    .section-header {
      font-size: 1.5rem;
      padding-top: 3.0em; } }
  @media (min-width: 1600px) {
    .section-header {
      font-size: 2vw; } }
  .section-header:after {
    content: "";
    display: block;
    background: #0075FF;
    width: 100px;
    max-width: 30%;
    height: 2px;
    margin: 0.9em auto 0; }

@font-face {
  font-family: 'Lato Bold';
  src: url("../fonts/Lato-Bold.ttf") format("truetype");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Lato Italic';
  src: url("../fonts/Lato-Italic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic; }

@font-face {
  font-family: 'Lato Medium';
  src: url("../fonts/Lato-Medium.ttf") format("truetype");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Lato Regular';
  src: url("../fonts/Lato-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal; }

/* ---icomoon START--- */
@font-face {
  font-family: 'icomoon';
  src: url("fonts/icomoon.eot?wy7hz7");
  src: url("fonts/icomoon.eot?wy7hz7#iefix") format("embedded-opentype"), url("fonts/icomoon.ttf?wy7hz7") format("truetype"), url("fonts/icomoon.woff?wy7hz7") format("woff"), url("fonts/icomoon.svg?wy7hz7#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block; }

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-reddit-alien:before {
  content: "\e900"; }

.icon-blogger:before {
  content: "\e901"; }

.icon-brand:before {
  content: "\e901"; }

.icon-social:before {
  content: "\e901"; }

.icon-livejournal:before {
  content: "\e902"; }

.icon-tiktok:before {
  content: "\e903"; }

.icon-my-world:before {
  content: "\e904"; }

.icon-mail-ru:before {
  content: "\e905"; }

.icon-yandex:before {
  content: "\e906"; }

.icon-egg:before {
  content: "\e907"; }

.icon-vk-market-squre:before {
  content: "\e908"; }

.icon-filtr:before {
  content: "\e909"; }

.icon-groop:before {
  content: "\e90a"; }

.icon-schedule:before {
  content: "\e90b"; }

.icon-stamp:before {
  content: "\e90c"; }

.icon-template:before {
  content: "\e90d"; }

.icon-trash:before {
  content: "\e90e"; }

.icon-sort-amount-desc:before {
  content: "\ea46"; }

.icon-tumblr:before {
  content: "\eab9"; }

.icon-calendar:before {
  content: "\f073"; }

.icon-comments:before {
  content: "\f086"; }

.icon-thumbs-o-up:before {
  content: "\f087"; }

.icon-twitter:before {
  content: "\f099"; }

.icon-facebook:before {
  content: "\f09a"; }

.icon-feed:before {
  content: "\f09e"; }

.icon-bars:before {
  content: "\f0c9"; }

.icon-linkedin:before {
  content: "\f0e1"; }

.icon-sitemap:before {
  content: "\f0e8"; }

.icon-angle-double-down:before {
  content: "\f103"; }

.icon-mobile:before {
  content: "\f10b"; }

.icon-spinner:before {
  content: "\f110"; }

.icon-rouble:before {
  content: "\f158"; }

.icon-youtube:before {
  content: "\f167"; }

.icon-instagram:before {
  content: "\f16d"; }

.icon-vk:before {
  content: "\f189"; }

.icon-paper-plane:before {
  content: "\f1d8"; }

.icon-connectdevelop:before {
  content: "\f20e"; }

.icon-pinterest-p:before {
  content: "\f231"; }

.icon-odnoklassniki:before {
  content: "\f263"; }

.icon-livejournal1:before {
  content: "\1f300"; }

/* ---icomoon END--- */
.hidden {
  display: none; }

section a.nodecor {
  color: black;
  text-decoration: none; }
  section a.nodecor:hover {
    color: #330867; }

.annotation-potential {
  text-align: center;
  margin-top: 20px; }

.is-centered {
  text-align: center;
  justify-content: center; }

.is-uppercase {
  text-transform: uppercase; }

.commonNav {
  position: sticky;
  background: #054D90;
  width: 100%;
  top: 0;
  z-index: 1; }
  .commonNav a {
    position: relative;
    display: block;
    padding: 1em;
    text-decoration: none;
    color: white;
    font-weight: lighter;
    font-size: 18px; }
    .commonNav a:before {
      position: absolute;
      content: '';
      left: 0;
      bottom: 0;
      width: 100%;
      height: 2px;
      background: white;
      transition: transform .5s ease;
      transform: scaleX(0); }
    .commonNav a:hover:before {
      transform: scaleX(1); }
    .commonNav a:not(:first-child) {
      display: none; }
  .commonNav.visible a {
    display: block; }
  .commonNav .login {
    display: flex !important;
    justify-content: center;
    align-items: center;
    padding: 0; }
    .commonNav .login p {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 2.5rem;
      width: 8.5rem;
      border-radius: 100px;
      border: 1px solid white; }

@media (max-width: 830px) {
  .commonNav {
    min-height: 46px; }
  .login {
    position: absolute !important;
    top: 0px;
    right: 6px;
    height: 100%; }
  .hamburger {
    background: url("../images/burger-icon.svg");
    background-repeat: no-repeat;
    background-position: 15px 14px; } }

@media (min-width: 829px) {
  .commonNav {
    display: flex;
    justify-content: space-around;
    transition: opacity .2s;
    z-index: 1; }
    .commonNav a {
      display: block !important; }
    .commonNav .hamburger {
      display: none !important; }
    .commonNav .login {
      order: 10; } }

video {
  filter: blur(2px);
  position: fixed;
  top: 50%;
  left: 50%;
  min-width: 101%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -100;
  transform: translateX(-50%) translateY(-50%);
  background: url("../images/cover.jpg") no-repeat;
  background-size: cover;
  transition: 1s opacity; }

.logoDescription {
  position: absolute;
  width: 100%;
  top: 75vh;
  color: white;
  text-shadow: -2px 2px 2px rgba(0, 0, 0, 0.8);
  text-align: center;
  z-index: 1; }
  @media (min-width: 600px) {
    .logoDescription {
      font-size: 3.75vmin;
      top: 55vh;
      width: 30vw;
      left: 34.5vw; } }

.scroll {
  color: rgba(255, 255, 255, 0.8);
  position: absolute;
  display: block;
  margin: 0 auto;
  top: 90vh;
  font-size: 3rem;
  animation: scroll;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  width: 100%;
  text-align: center; }

#firstSection {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background: radial-gradient(circle closest-side at center, #1e5799 0%, rgba(161, 181, 203, 0) 80%, rgba(229, 229, 229, 0) 100%); }

#bubbles-holder {
  position: relative; }
  #bubbles-holder #bubbles {
    width: 12vmin;
    height: 12vmin;
    overflow: visible;
    position: relative;
    padding-top: 6vmin; }

.bubbles_line {
  width: 30vmin;
  height: 2px;
  position: absolute;
  right: 50%;
  background: linear-gradient(to right, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0.3) 40%, white 50%, rgba(255, 255, 255, 0.3) 60%, rgba(255, 255, 255, 0.3) 100%);
  background-size: 240% 100%;
  transform-origin: 35.5813953488vmin center;
  margin-right: 5.5813953488vmin; }
  .bubbles_line::before {
    position: absolute;
    left: -3.5vmin;
    bottom: -3.5vmin;
    display: block;
    width: 7vmin;
    height: 7vmin;
    border-radius: 100%;
    line-height: 7vmin;
    text-align: center;
    font-family: icomoon;
    font-size: 4.2vmin;
    color: white;
    border: 2px solid white;
    transition-property: border-width;
    transition-duration: 100ms; }
  .bubbles_line:hover::before {
    border-width: 3px;
    transition-property: border-width;
    transition-duration: 100ms; }
  .bubbles_line.in {
    animation-name: in;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear; }
  .bubbles_line.out {
    animation-name: out;
    animation-delay: 2s;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear; }

@media (max-aspect-ratio: 8 / 10) {
  .bubbles_line {
    width: 39vmin;
    transform-origin: 44.3846153846vmin center;
    margin-right: 5.3846153846vmin; }
    .bubbles_line:before {
      width: 8.4vmin;
      height: 8.4vmin;
      line-height: 8.4vmin;
      bottom: -4.2vmin;
      left: -4.2vmin; } }

@keyframes in {
  from {
    background-position: 100% 50%; }
  to {
    background-position: 0% 50%; } }

@keyframes out {
  from {
    background-position: 0% 50%; }
  to {
    background-position: 100% 50%; } }

@keyframes scroll {
  0% {
    top: 90vh; }
  80% {
    top: 90vh; }
  90% {
    top: 92vh; }
  100% {
    top: 90vh; } }

.flip-container:hover .flipper {
  transform: rotateY(180deg); }

.flipper {
  transition: 0.4s;
  transform-style: preserve-3d;
  position: relative; }

.front, .back {
  backface-visibility: hidden; }

.possib {
  margin-right: -5px;
  margin-left: -5px; }

.possibility {
  font-family: "Lato Medium";
  text-align: center;
  font-size: 1.1vw;
  line-height: 1.8vw; }
  @media (min-width: 992px) {
    .possibility {
      width: 16.06666666666666666666%;
      margin: 0px 0.3% 0.6% 0.3%; } }
  @media (min-width: 500px) and (max-width: 991px) {
    .possibility {
      width: 32.733333333333333333333333333%;
      margin: 0px 0.3% 0.6% 0.3%;
      font-size: 2.2vw;
      line-height: 3.5vw; } }
  @media (min-width: 320px) and (max-width: 499px) {
    .possibility {
      width: 48.4%;
      margin: 0px 0.8% 1.6% 0.8%;
      font-size: 3.2vw;
      line-height: 4.8vw; } }
  .possibility .front {
    background-size: 100% auto;
    background-image: radial-gradient(circle at center, #014BD9, #02266C, #031332, #000D25);
    z-index: 2;
    transform: rotateY(0deg); }
    .possibility .front .part {
      display: flex;
      position: relative;
      justify-content: center;
      color: #fff;
      text-transform: uppercase;
      font-weight: 600;
      height: 16vw;
      padding: 1vw; }
      @media (min-width: 500px) and (max-width: 991px) {
        .possibility .front .part {
          height: 33vw;
          padding: 3vw; } }
      @media (min-width: 320px) and (max-width: 499px) {
        .possibility .front .part {
          height: 50vw;
          padding: 3vw; } }
    .possibility .front .image-possibility {
      position: absolute;
      top: 5.2vw;
      margin-left: auto;
      margin-right: auto;
      width: 50%; }
      @media (min-width: 500px) and (max-width: 991px) {
        .possibility .front .image-possibility {
          top: 11vw; } }
      @media (min-width: 320px) and (max-width: 499px) {
        .possibility .front .image-possibility {
          top: 15vw; } }
  .possibility .back {
    width: 100%;
    background: #ffffff;
    box-shadow: inset 0 0 6px #0058FF;
    border: 2px solid #D7D7D7;
    transform: rotateY(180deg);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10; }
    .possibility .back:hover {
      text-decoration: none; }
    .possibility .back .part {
      display: flex;
      justify-content: center;
      color: #0058FF;
      text-transform: uppercase;
      font-weight: 600;
      height: 16vw;
      padding: 1vw; }
      .possibility .back .part .annotation {
        text-align: left;
        color: #000;
        text-transform: none;
        line-height: 1.2vw;
        font-weight: 400;
        padding-top: 15px; }
        .possibility .back .part .annotation.full-funktion {
          margin-top: -30px; }
        @media (min-width: 320px) and (max-width: 499px) {
          .possibility .back .part .annotation {
            line-height: 4.2vw; } }
        @media (min-width: 500px) and (max-width: 991px) {
          .possibility .back .part .annotation {
            line-height: 2.7vw;
            padding-top: 5px; } }
      @media (min-width: 500px) and (max-width: 991px) {
        .possibility .back .part {
          height: 33vw;
          padding: 3vw; } }
      @media (min-width: 320px) and (max-width: 499px) {
        .possibility .back .part {
          height: 50vw;
          padding: 3vw; } }
    .possibility .back .image-possibility {
      position: absolute;
      bottom: 1.0vw;
      right: 1.0vw;
      width: 20%;
      animation-name: rotare;
      animation-duration: 1.5s;
      animation-iteration-count: infinite;
      animation-timing-function: linear; }

@keyframes rotare {
  0% {
    filter: brightness(100%) drop-shadow(0 12px 10px #ffffff); }
  80% {
    filter: brightness(100%) drop-shadow(0 12px 10px #ffffff); }
  90% {
    filter: brightness(200%) drop-shadow(0 12px 50px #ffffff); }
  100% {
    filter: brightness(100%) drop-shadow(0 12px 10px #ffffff); } }
      @media (min-width: 500px) and (max-width: 991px) {
        .possibility .back .image-possibility {
          top: 25vw;
          right: 1.5vw; } }
      @media (min-width: 320px) and (max-width: 499px) {
        .possibility .back .image-possibility {
          top: 38vw;
          right: 2.5vw; } }

.feature {
  text-align: center;
  padding-bottom: 30px; }
  @media (max-width: 767px) {
    .feature {
      padding-bottom: 0px; } }
  .feature h2 {
    font-family: "Lato Medium";
    font-weight: bold;
    padding-top: 14px; }
    @media (max-width: 1199px) {
      .feature h2 {
        font-size: 24px; } }
  .feature img {
    width: 100%; }
  .feature span.analytic {
    font-family: "Lato Medium";
    display: inline-block;
    font-weight: bold;
    padding-bottom: 1.2em; }
  .feature span.prostobold {
    font-family: "Lato Medium";
    font-weight: bold;
    padding: 0 5px; }
  .feature .box-analytic {
    height: 28vw;
    width: 100%;
    display: flex;
    padding-bottom: 30px;
    align-items: flex-end; }
    @media (max-width: 767px) {
      .feature .box-analytic {
        height: 24em;
        padding-bottom: 0px; } }
    .feature .box-analytic.analyz-1 {
      background: url("/templates/seolit-mdl-index/assets/images/analyz-1.jpg") no-repeat top center;
      background-size: 100% auto; }
    .feature .box-analytic.analyz-2 {
      background: url("/templates/seolit-mdl-index/assets/images/analyz-2.jpg") no-repeat top center;
      background-size: 100% auto; }
    .feature .box-analytic.analyz-3 {
      background: url("/templates/seolit-mdl-index/assets/images/analyz-3.jpg") no-repeat top center;
      background-size: 100% auto; }
    .feature .box-analytic.analyz-4 {
      background: url("/templates/seolit-mdl-index/assets/images/analyz-4.jpg") no-repeat top center;
      background-size: 100% auto; }
    .feature .box-analytic.preoritet-1 {
      background: url("/templates/seolit-mdl-index/assets/images/preoritet-1.jpg") no-repeat top left;
      background-size: 100% auto; }
    .feature .box-analytic.preoritet-2 {
      background: url("/templates/seolit-mdl-index/assets/images/preoritet-2.jpg") no-repeat top left;
      background-size: 100% auto; }
    .feature .box-analytic.preoritet-3 {
      background: url("/templates/seolit-mdl-index/assets/images/preoritet-3.jpg") no-repeat top left;
      background-size: 100% auto; }
    .feature .box-analytic.preoritet-4 {
      background: url("/templates/seolit-mdl-index/assets/images/preoritet-4.jpg") no-repeat top left;
      background-size: 100% auto; }
  .feature .box-analytic-st {
    height: 20vw;
    width: 100%;
    display: flex;
    padding-bottom: 0px;
    align-items: center; }
    @media (max-width: 767px) {
      .feature .box-analytic-st {
        height: 16em;
        margin-bottom: 1px; } }
    .feature .box-analytic-st.non-standart-1 {
      background: url("/templates/seolit-mdl-index/assets/images/non-standart-001.jpg") no-repeat top left; }
    .feature .box-analytic-st.non-standart-2 {
      background: url("/templates/seolit-mdl-index/assets/images/non-standart-002.jpg") no-repeat top center;
      background-size: 100% auto; }
    .feature .box-analytic-st.non-standart-3 {
      background: url("/templates/seolit-mdl-index/assets/images/non-standart-003.jpg") no-repeat top center;
      background-size: 100% auto; }
    .feature .box-analytic-st.non-standart-4 {
      background: url("/templates/seolit-mdl-index/assets/images/non-standart-004.jpg") no-repeat top center;
      background-size: 100% auto; }
  .feature .frame-annatation {
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.85); }
  .feature .frame-annatation-st {
    width: 100%;
    min-height: 95px;
    align-items: center;
    display: flex;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.85); }

.textGradient {
  color: #30cfd0;
  background: -webkit-linear-gradient(180deg, #130B76, #130B76, #005CDB, #25064A, #25064A);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; }

.spider-logo {
  display: flex;
  align-items: center;
  justify-content: center; }

@media (max-width: 48em) {
  .desktop-only {
    display: none; } }

.weapon.left:not(:last-child), .weapon.right:not(:last-child) {
  padding-bottom: 1.5rem; }

#secondSection, #fourthSection {
  padding-bottom: 5rem; }

#thirdSection {
  padding-bottom: 1rem; }
  #thirdSection .section-header {
    padding-top: 1em; }

#sixSection .action__btn_white {
  display: inline-block;
  max-width: 90%;
  background: rgba(255, 255, 255, 0.2);
  padding: .7em 3.5em;
  border-radius: 30px;
  text-decoration: none;
  color: white;
  transition: all .2s;
  border: 2px solid white;
  text-transform: uppercase; }
  #sixSection .action__btn_white:hover {
    background: white;
    color: #330867; }

#sixSection .pricecard {
  background: linear-gradient(to left, #30cfd0, #330867);
  margin-bottom: 100px;
  border-radius: 20px;
  color: white;
  padding: 2rem 0; }
  #sixSection .pricecard h2 {
    font-weight: normal;
    padding-top: 0; }
  #sixSection .pricecard .leftPrice {
    border-right: 1px solid white; }
  #sixSection .pricecard .price {
    font-size: 2rem; }
  #sixSection .pricecard sup {
    font-size: 1rem; }
  #sixSection .pricecard .month {
    padding-top: .3rem;
    padding-bottom: 1.5rem;
    color: rgba(255, 255, 255, 0.6); }
  #sixSection .pricecard ul {
    list-style: none; }
    #sixSection .pricecard ul li {
      padding: 1rem; }
  #sixSection .pricecard .promo {
    padding-top: 2rem;
    font-size: 2.5rem;
    font-weight: bold; }

.spider {
  display: none; }

.flex-spider {
  display: flex;
  flex-wrap: wrap; }

@media (min-width: 800px) {
  .spider {
    display: block;
    position: absolute;
    border: 1px solid gray; }
    .spider:before {
      display: block;
      width: 6px;
      height: 6px;
      border-radius: 6px;
      background: gray;
      content: "";
      position: absolute; }
    .spider:after {
      display: block;
      width: 6px;
      height: 6px;
      border-radius: 6px;
      background: gray;
      content: "";
      position: absolute; }
  .spider1 {
    width: 100px;
    height: 50px;
    right: -120px;
    top: 50px;
    border-bottom: 0;
    border-left: 0; }
    .spider1:before {
      top: -3px; }
    .spider1:after {
      bottom: -3px;
      right: -3px; }
  .spider2 {
    width: 20px;
    height: 0;
    right: -40px;
    top: 50px;
    border-bottom: 0;
    border-left: 0; }
    .spider2:before {
      top: -3px; }
    .spider2:after {
      bottom: -3px;
      right: -3px; }
  .spider3 {
    width: 100px;
    height: 50px;
    right: -120px;
    top: 20px;
    border-top: 0;
    border-left: 0; }
    .spider3:before {
      bottom: -3px; }
    .spider3:after {
      top: -3px;
      right: -3px; }
  .spider4 {
    width: 100px;
    height: 50px;
    left: -120px;
    top: 50px;
    border-bottom: 0;
    border-right: 0; }
    .spider4:before {
      left: -3px;
      bottom: -3px; }
    .spider4:after {
      top: -3px;
      right: -3px; }
  .spider5 {
    width: 20px;
    height: 0px;
    left: -40px;
    top: 50px;
    border-bottom: 0;
    border-left: 0; }
    .spider5:before {
      top: -3px; }
    .spider5:after {
      bottom: -3px;
      right: -3px; }
  .spider6 {
    width: 100px;
    height: 50px;
    left: -120px;
    top: 38px;
    border-top: 0;
    border-right: 0; }
    .spider6:before {
      bottom: -3px;
      right: -3px; }
    .spider6:after {
      top: -3px;
      left: -3px; }
  .weapon {
    position: relative; } }

/*
 *	HTML5 Device Mockups
 *
 *	By:
 *		Tomi Hiltunen
 *		tomi@mitakuuluu.fi
 *		http://fi.linkedin.com/in/tomihiltunen/
 *
 *		Angelos Arnis
 *		http://fi.linkedin.com/in/angelosarnis/
 *
 *	Inluded in this package:
 *		- iPhone5 (black/white, portrait/landscape)
 *		- iPad2 (black/white, portrait/landscape)
 *		- iMac
 *		- MacBook Pro (retina model)
 *		- Nexus 7
 *		- Lumia 920
 *		- Microsoft Surface
 *		- Galaxy S3
 *
 *  Added by ben182 (@beno182):
 *		- iPhone 6
 *  	- iPhone 6 Plus
 *		- iPhone SE
 *		- iPad Air
 *		- iPad Air 2
 *		- iPad Pro
 *		- Macbook 2015
 *
 *
 */
/* GENERAL STYLE */
.device-container {
  max-width: 300px; }

.device-mockup {
  position: relative;
  width: 100%;
  padding-bottom: 61.775701%; }

.device-mockup > .device {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-image: url("macbook/macbook.png"); }

.device-mockup > .device > .screen {
  position: absolute;
  top: 11.0438729%;
  bottom: 14.6747352%;
  left: 13.364486%;
  right: 13.364486%;
  overflow: hidden; }

.device-mockup > .device > .button {
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  overflow: hidden;
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  cursor: pointer; }

/* DEVICES */
/* iMac */
.device-mockup.imac {
  padding-bottom: 81.230769%; }

.device-mockup.imac > .device {
  background-image: url("imac/imac.png"); }

.device-mockup.imac > .device > .screen {
  top: 8.20707071%;
  bottom: 31.6919192%;
  left: 6.61538462%;
  right: 6.61538462%; }

/* MacBook Pro */
.device-mockup.macbook {
  padding-bottom: 61.775701%; }

.device-mockup.macbook > .device {
  background-image: url("macbook/macbook.png"); }

.device-mockup.macbook > .device > .screen {
  top: 11.0438729%;
  bottom: 14.6747352%;
  left: 13.364486%;
  right: 13.364486%; }

/* MacBook 2015 */
.device-mockup.macbook_2015 {
  padding-bottom: 57.50%; }

.device-mockup.macbook_2015 > .device,
.device-mockup.macbook_2015.grey > .device {
  background-image: url("macbook_2015/grey.png"); }

.device-mockup.macbook_2015 > .device,
.device-mockup.macbook_2015.silver > .device {
  background-image: url("macbook_2015/silver.png"); }

.device-mockup.macbook_2015 > .device,
.device-mockup.macbook_2015.gold > .device {
  background-image: url("macbook_2015/gold.png"); }

.device-mockup.macbook_2015 > .device > .screen {
  top: 7.5%;
  bottom: 10.9%;
  left: 12.46%;
  right: 12.56%; }

/* iPhone 5 */
.device-mockup.iphone5,
.device-mockup.iphone5.portrait {
  padding-bottom: 210.57%; }

.device-mockup.iphone5.landscape {
  padding-bottom: 47.49%; }

.device-mockup.iphone5 > .device,
.device-mockup.iphone5.black > .device,
.device-mockup.iphone5.portrait.black > .device {
  background-image: url("iphone5/iphone5_port_black.png"); }

.device-mockup.iphone5.landscape > .device,
.device-mockup.iphone5.landscape.black > .device {
  background-image: url("iphone5/iphone5_land_black.png"); }

.device-mockup.iphone5.white > .device,
.device-mockup.iphone5.portrait.white > .device {
  background-image: url("iphone5/iphone5_port_white.png"); }

.device-mockup.iphone5.landscape.white > .device {
  background-image: url("iphone5/iphone5_land_white.png"); }

.device-mockup.iphone5 > .device > .screen,
.device-mockup.iphone5.portrait > .device > .screen {
  top: 14.78%;
  bottom: 13.78%;
  left: 8.77%;
  right: 7.77%; }

.device-mockup.iphone5.landscape > .device > .screen {
  top: 7.422488%;
  bottom: 8.422488%;
  left: 14.919127%;
  right: 14.019127%; }

.device-mockup.iphone5 > .device > .button,
.device-mockup.iphone5.portrait > .device > .button {
  display: block;
  top: 88.3%;
  bottom: 2.5%;
  left: 40%;
  right: 40%; }

.device-mockup.iphone5.landscape > .device > .button {
  display: block;
  top: 40%;
  bottom: 41%;
  left: 88.4%;
  right: 2.3%; }

/* iPhone SE */
.device-mockup.iphone_se,
.device-mockup.iphone_se.portrait {
  padding-bottom: 209.83%; }

.device-mockup.iphone_se.landscape {
  padding-bottom: 47.66%; }

.device-mockup.iphone_se > .device,
.device-mockup.iphone_se.black > .device,
.device-mockup.iphone_se.portrait.black > .device {
  background-image: url("iphone_se/iphone_se_port_black.png"); }

.device-mockup.iphone_se.landscape > .device,
.device-mockup.iphone_se.landscape.black > .device {
  background-image: url("iphone_se/iphone_se_land_black.png"); }

.device-mockup.iphone_se.white > .device,
.device-mockup.iphone_se.portrait.white > .device {
  background-image: url("iphone_se/iphone_se_port_white.png"); }

.device-mockup.iphone_se.landscape.white > .device {
  background-image: url("iphone_se/iphone_se_land_white.png"); }

.device-mockup.iphone_se.gold > .device,
.device-mockup.iphone_se.portrait.gold > .device {
  background-image: url("../images/iphone_se_port_gold.png"); }

.device-mockup.iphone_se.landscape.gold > .device {
  background-image: url("iphone_se/iphone_se_land_gold.png"); }

.device-mockup.iphone_se.rose > .device,
.device-mockup.iphone_se.portrait.rose > .device {
  background-image: url("iphone_se/iphone_se_port_rose.png"); }

.device-mockup.iphone_se.landscape.rose > .device {
  background-image: url("iphone_se/iphone_se_land_rose.png"); }

.device-mockup.iphone_se > .device > .screen,
.device-mockup.iphone_se.portrait > .device > .screen {
  top: 14.75%;
  bottom: 14.28%;
  left: 8.5%;
  right: 7.5%; }

.device-mockup.iphone_se.landscape > .device > .screen {
  top: 11.722488%;
  bottom: 11.722488%;
  left: 16.0191273%;
  right: 16.0191273%; }

.device-mockup.iphone_se > .device > .button,
.device-mockup.iphone_se.portrait > .device > .button {
  display: block;
  top: 86.3%;
  bottom: 5.5%;
  left: 42%;
  right: 42%; }

.device-mockup.iphone_se.landscape > .device > .button {
  display: block;
  top: 42%;
  bottom: 42%;
  left: 86.4%;
  right: 5.1%; }

/* iPhone 6 */
.device-mockup.iphone6,
.device-mockup.iphone6.portrait {
  padding-bottom: 207.19%; }

.device-mockup.iphone6.landscape {
  padding-bottom: 48.76%; }

.device-mockup.iphone6 > .device,
.device-mockup.iphone6.black > .device,
.device-mockup.iphone6.portrait.black > .device {
  background-image: url("iphone_6/iphone_6_port_black.png"); }

.device-mockup.iphone6.landscape > .device,
.device-mockup.iphone6.landscape.black > .device {
  background-image: url("iphone_6/iphone_6_land_black.png"); }

.device-mockup.iphone6.white > .device,
.device-mockup.iphone6.portrait.white > .device {
  background-image: url("iphone_6/iphone_6_port_white.png"); }

.device-mockup.iphone6.landscape.white > .device {
  background-image: url("iphone_6/iphone_6_land_white.png"); }

.device-mockup.iphone6.gold > .device,
.device-mockup.iphone6.portrait.gold > .device {
  background-image: url("iphone_6/iphone_6_port_gold.png"); }

.device-mockup.iphone6.landscape.gold > .device {
  background-image: url("iphone_6/iphone_6_land_gold.png"); }

.device-mockup.iphone6 > .device > .screen,
.device-mockup.iphone6.portrait > .device > .screen {
  top: 12.58%;
  bottom: 12.88%;
  left: 6.77%;
  right: 6.77%; }

.device-mockup.iphone6.landscape > .device > .screen {
  top: 6.77%;
  bottom: 6.77%;
  left: 12.58%;
  right: 12.88%; }

.device-mockup.iphone6 > .device > .button,
.device-mockup.iphone6.portrait > .device > .button {
  display: block;
  top: 88.6%;
  bottom: 3.2%;
  left: 42%;
  right: 42%; }

.device-mockup.iphone6.landscape > .device > .button {
  display: block;
  top: 42%;
  bottom: 42%;
  left: 88.6%;
  right: 3.2%; }

/* iPhone 6 Plus*/
.device-mockup.iphone6_plus,
.device-mockup.iphone6_plus.portrait {
  padding-bottom: 201.76%; }

.device-mockup.iphone6_plus.landscape {
  padding-bottom: 49.56%; }

.device-mockup.iphone6_plus > .device,
.device-mockup.iphone6_plus.black > .device,
.device-mockup.iphone6_plus.portrait.black > .device {
  background-image: url("iphone_6_plus/iphone_6_plus_black_port.png"); }

.device-mockup.iphone6_plus.landscape > .device,
.device-mockup.iphone6_plus.landscape.black > .device {
  background-image: url("iphone_6_plus/iphone_6_plus_black_land.png"); }

.device-mockup.iphone6_plus.white > .device,
.device-mockup.iphone6_plus.portrait.white > .device {
  background-image: url("iphone_6_plus/iphone_6_plus_white_port.png"); }

.device-mockup.iphone6_plus.landscape.white > .device {
  background-image: url("iphone_6_plus/iphone_6_plus_white_land.png"); }

.device-mockup.iphone6_plus.gold > .device,
.device-mockup.iphone6_plus.portrait.gold > .device {
  background-image: url("iphone_6_plus/iphone_6_plus_gold_port.png"); }

.device-mockup.iphone6_plus.landscape.gold > .device {
  background-image: url("iphone_6_plus/iphone_6_plus_gold_land.png"); }

.device-mockup.iphone6_plus > .device > .screen,
.device-mockup.iphone6_plus.portrait > .device > .screen {
  top: 11.58%;
  bottom: 11.58%;
  left: 5.97%;
  right: 5.97%; }

.device-mockup.iphone6_plus.landscape > .device > .screen {
  top: 5.97%;
  bottom: 5.97%;
  left: 11.38%;
  right: 11.58%; }

.device-mockup.iphone6_plus > .device > .button,
.device-mockup.iphone6_plus.portrait > .device > .button {
  display: block;
  top: 89.9%;
  bottom: 3.2%;
  left: 43%;
  right: 43%; }

.device-mockup.iphone6_plus.landscape > .device > .button {
  display: block;
  top: 43%;
  bottom: 43%;
  left: 89.9%;
  right: 3.2%; }

/* iPad */
.device-mockup.ipad,
.device-mockup.ipad.portrait {
  padding-bottom: 128.406276%; }

.device-mockup.ipad.landscape {
  padding-bottom: 79.9086758%; }

.device-mockup.ipad > .device,
.device-mockup.ipad.black > .device,
.device-mockup.ipad.portrait.black > .device {
  background-image: url("ipad/ipad_port_black.png"); }

.device-mockup.ipad.white > .device,
.device-mockup.ipad.portrait.white > .device {
  background-image: url("ipad/ipad_port_white.png"); }

.device-mockup.ipad.landscape > .device,
.device-mockup.ipad.landscape.black > .device {
  background-image: url("ipad/ipad_land_black.png"); }

.device-mockup.ipad.landscape.white > .device {
  background-image: url("ipad/ipad_land_white.png"); }

.device-mockup.ipad > .device > .screen,
.device-mockup.ipad.portrait > .device > .screen {
  top: 12.025723%;
  bottom: 12.154341%;
  left: 13.45995%;
  right: 13.45995%; }

.device-mockup.ipad.landscape > .device > .screen {
  top: 13.87755102%;
  bottom: 13.87755102%;
  left: 11.5459883%;
  right: 11.5459883%; }

.device-mockup.ipad > .device > .button,
.device-mockup.ipad.portrait > .device > .button {
  display: block;
  top: 90.2%;
  bottom: 5.5%;
  left: 47.3%;
  right: 47.3%; }

.device-mockup.ipad.landscape > .device > .button {
  display: block;
  top: 47.3%;
  bottom: 47.3%;
  left: 90.8%;
  right: 4.9%; }

/* iPad Air */
.device-mockup.ipad_air,
.device-mockup.ipad_air.portrait {
  padding-bottom: 141.27%; }

.device-mockup.ipad_air.landscape {
  padding-bottom: 70.79%; }

.device-mockup.ipad_air > .device,
.device-mockup.ipad_air.grey > .device,
.device-mockup.ipad_air.portrait.grey > .device {
  background-image: url("ipad_air/ipad_air_gray_port.png"); }

.device-mockup.ipad_air.silver > .device,
.device-mockup.ipad_air.portrait.silver > .device {
  background-image: url("ipad_air/ipad_air_silver_port.png"); }

.device-mockup.ipad_air.landscape > .device,
.device-mockup.ipad_air.landscape.grey > .device {
  background-image: url("ipad_air/ipad_air_gray_land.png"); }

.device-mockup.ipad_air.landscape.silver > .device {
  background-image: url("ipad_air/ipad_air_silver_land.png"); }

.device-mockup.ipad_air > .device > .screen,
.device-mockup.ipad_air.portrait > .device > .screen {
  top: 9.025723%;
  bottom: 9.154341%;
  left: 7.45995%;
  right: 6.45995%; }

.device-mockup.ipad_air.landscape > .device > .screen {
  top: 6.877551%;
  bottom: 6.877551%;
  left: 9.545988%;
  right: 9.545988%; }

.device-mockup.ipad_air > .device > .button,
.device-mockup.ipad_air.portrait > .device > .button {
  display: block;
  top: 93.2%;
  bottom: 2.5%;
  left: 47.3%;
  right: 47.3%; }

.device-mockup.ipad_air.landscape > .device > .button {
  display: block;
  top: 47.3%;
  bottom: 47.3%;
  left: 93.1%;
  right: 2.1%; }

/* iPad Air 2 */
.device-mockup.ipad_air_2,
.device-mockup.ipad_air_2.portrait {
  padding-bottom: 141.38%; }

.device-mockup.ipad_air_2.landscape {
  padding-bottom: 70.73%; }

.device-mockup.ipad_air_2 > .device,
.device-mockup.ipad_air_2.grey > .device,
.device-mockup.ipad_air_2.portrait.grey > .device {
  background-image: url("ipad_air_2/ipad_air_2_gray_port.png"); }

.device-mockup.ipad_air_2.silver > .device,
.device-mockup.ipad_air_2.portrait.silver > .device {
  background-image: url("ipad_air_2/ipad_air_2_silver_port.png"); }

.device-mockup.ipad_air_2.gold > .device,
.device-mockup.ipad_air_2.portrait.gold > .device {
  background-image: url("ipad_air_2/ipad_air_2_gold_port.png"); }

.device-mockup.ipad_air_2.landscape > .device,
.device-mockup.ipad_air_2.landscape.grey > .device {
  background-image: url("ipad_air_2/ipad_air_2_gray_land.png"); }

.device-mockup.ipad_air_2.landscape.silver > .device {
  background-image: url("ipad_air_2/ipad_air_2_silver_land.png"); }

.device-mockup.ipad_air_2.landscape.gold > .device {
  background-image: url("ipad_air_2/ipad_air_2_gold_land.png"); }

.device-mockup.ipad_air_2 > .device > .screen,
.device-mockup.ipad_air_2.portrait > .device > .screen {
  top: 9.025723%;
  bottom: 8.854341%;
  left: 6.15995%;
  right: 6.15995%; }

.device-mockup.ipad_air_2.landscape > .device > .screen {
  top: 6.277551%;
  bottom: 6.277551%;
  left: 8.545988%;
  right: 8.545988%; }

.device-mockup.ipad_air_2 > .device > .button,
.device-mockup.ipad_air_2.portrait > .device > .button {
  display: block;
  top: 93.2%;
  bottom: 2.1%;
  left: 46.3%;
  right: 46.5%; }

.device-mockup.ipad_air_2.landscape > .device > .button {
  display: block;
  top: 46.5%;
  bottom: 46.8%;
  left: 93.5%;
  right: 1.9%; }

/* iPad Pro */
.device-mockup.ipad_pro,
.device-mockup.ipad_pro.portrait {
  padding-bottom: 138.63%; }

.device-mockup.ipad_pro.landscape {
  padding-bottom: 72.14%; }

.device-mockup.ipad_pro > .device,
.device-mockup.ipad_pro.grey > .device,
.device-mockup.ipad_pro.portrait.grey > .device {
  background-image: url("ipad_pro/ipad_pro_port_black.png"); }

.device-mockup.ipad_pro.silver > .device,
.device-mockup.ipad_pro.portrait.silver > .device {
  background-image: url("ipad_pro/ipad_pro_port_silver.png"); }

.device-mockup.ipad_pro.gold > .device,
.device-mockup.ipad_pro.portrait.gold > .device {
  background-image: url("ipad_pro/ipad_pro_port_gold.png"); }

.device-mockup.ipad_pro.landscape > .device,
.device-mockup.ipad_pro.landscape.grey > .device {
  background-image: url("ipad_pro/ipad_pro_land_black.png"); }

.device-mockup.ipad_pro.landscape.silver > .device {
  background-image: url("ipad_pro/ipad_pro_land_silver.png"); }

.device-mockup.ipad_pro.landscape.gold > .device {
  background-image: url("ipad_pro/ipad_pro_land_gold.png"); }

.device-mockup.ipad_pro > .device > .screen,
.device-mockup.ipad_pro.portrait > .device > .screen {
  top: 7.025723%;
  bottom: 6.854341%;
  left: 5.15995%;
  right: 5.15995%; }

.device-mockup.ipad_pro.landscape > .device > .screen {
  top: 5.177551%;
  bottom: 5.177551%;
  left: 6.945988%;
  right: 6.945988%; }

.device-mockup.ipad_pro > .device > .button,
.device-mockup.ipad_pro.portrait > .device > .button {
  display: block;
  top: 94.9%;
  bottom: 1.5%;
  left: 47.3%;
  right: 47.1%; }

.device-mockup.ipad_pro.landscape > .device > .button {
  display: block;
  top: 47.5%;
  bottom: 47.5%;
  left: 94.8%;
  right: 1.5%; }

/* Galaxy S3 */
.device-mockup.galaxy_s3,
.device-mockup.galaxy_s3.portrait {
  padding-bottom: 178.787879%; }

.device-mockup.galaxy_s3.landscape {
  padding-bottom: 55.9322034%; }

.device-mockup.galaxy_s3 > .device,
.device-mockup.galaxy_s3.black > .device,
.device-mockup.galaxy_s3.portrait.black > .device {
  background-image: url("galaxy_s3/s3_port_black.png"); }

.device-mockup.galaxy_s3.landscape > .device,
.device-mockup.galaxy_s3.landscape.black > .device {
  background-image: url("galaxy_s3/s3_land_black.png"); }

.device-mockup.galaxy_s3.white > .device,
.device-mockup.galaxy_s3.portrait.white > .device {
  background-image: url("galaxy_s3/s3_port_white.png"); }

.device-mockup.galaxy_s3.landscape.white > .device {
  background-image: url("galaxy_s3/s3_land_white.png"); }

.device-mockup.galaxy_s3 > .device > .screen,
.device-mockup.galaxy_s3.portrait > .device > .screen {
  top: 12.3728814%;
  bottom: 15.5367232%;
  left: 13.8383838%;
  right: 13.8383838%; }

.device-mockup.galaxy_s3.landscape > .device > .screen {
  top: 13.8383838%;
  bottom: 13.8383838%;
  left: 12.3728814%;
  right: 15.5367232%; }

/* Galaxy S5 */
.device-mockup.galaxy_s5,
.device-mockup.galaxy_s5.portrait {
  padding-bottom: 194.188862%; }

.device-mockup.galaxy_s5.landscape {
  padding-bottom: 51.496259%; }

.device-mockup.galaxy_s5 > .device,
.device-mockup.galaxy_s5.black > .device,
.device-mockup.galaxy_s5.portrait.black > .device {
  background-image: url("galaxy_s5/galaxy_s5_port_black.png"); }

.device-mockup.galaxy_s5.landscape > .device,
.device-mockup.galaxy_s5.landscape.black > .device {
  background-image: url("galaxy_s5/galaxy_s5_land_black.png"); }

.device-mockup.galaxy_s5.white > .device,
.device-mockup.galaxy_s5.portrait.white > .device {
  background-image: url("galaxy_s5/galaxy_s5_port_white.png"); }

.device-mockup.galaxy_s5.landscape.white > .device {
  background-image: url("galaxy_s5/galaxy_s5_land_white.png"); }

.device-mockup.galaxy_s5 > .device > .screen,
.device-mockup.galaxy_s5.portrait > .device > .screen {
  top: 10.2%;
  bottom: 10.1%;
  left: 6.3%;
  right: 6.4%; }

.device-mockup.galaxy_s5.landscape > .device > .screen {
  top: 6.6%;
  bottom: 6.2%;
  left: 10.15%;
  right: 10%; }

/* Galaxy Tab4 */
.device-mockup.galaxy_tab4,
.device-mockup.galaxy_tab4.landscape {
  padding-bottom: 72.689464%; }

.device-mockup.galaxy_tab4 > .device,
.device-mockup.galaxy_tab4.black > .device,
.device-mockup.galaxy_tab4.landscape.black > .device {
  background-image: url("galaxy_tab4/galaxy_tab4_land_black.png"); }

.device-mockup.galaxy_tab4.white > .device,
.device-mockup.galaxy_tab4.landscape.white > .device {
  background-image: url("galaxy_tab4/galaxy_tab4_land_white.png"); }

.device-mockup.galaxy_tab4 > .device > .screen,
.device-mockup.galaxy_tab4.landscape > .device > .screen {
  top: 11.5%;
  bottom: 11.5%;
  left: 5.4%;
  right: 5.2%; }

/* Lumia 920 */
.device-mockup.lumia920,
.device-mockup.lumia920.portrait {
  padding-bottom: 172.796353%; }

.device-mockup.lumia920.landscape {
  padding-bottom: 56.3330381%; }

.device-mockup.lumia920 > .device,
.device-mockup.lumia920.portrait > .device {
  background-image: url("lumia920/lumia920_port.png"); }

.device-mockup.lumia920.landscape > .device {
  background-image: url("lumia920/lumia920_land.png"); }

.device-mockup.lumia920 > .device > .screen,
.device-mockup.lumia920.portrait > .device > .screen {
  top: 10.6420405%;
  bottom: 18.9973615%;
  left: 13.5258359%;
  right: 13.5258359%; }

.device-mockup.lumia920.landscape > .device > .screen {
  top: 12.2641509%;
  bottom: 12.2641509%;
  left: 10.5403012%;
  right: 18.6005314%; }

/* Nexus 7 */
.device-mockup.nexus7,
.device-mockup.nexus7.portrait {
  padding-bottom: 156.521739%; }

.device-mockup.nexus7.landscape {
  padding-bottom: 63.6678201%; }

.device-mockup.nexus7 > .device,
.device-mockup.nexus7.portrait > .device {
  background-image: url("nexus7/nexus7_port.png"); }

.device-mockup.nexus7.landscape > .device {
  background-image: url("nexus7/nexus7_land.png"); }

.device-mockup.nexus7 > .device > .screen,
.device-mockup.nexus7.portrait > .device > .screen {
  top: 13.1365741%;
  bottom: 12.7893519%;
  left: 13.7681159%;
  right: 13.7681159%; }

.device-mockup.nexus7.landscape > .device > .screen {
  top: 13.7681159%;
  bottom: 13.7681159%;
  left: 13.0911188%;
  right: 13.0911188%; }

/* Microsoft Surface */
.device-mockup.surface,
.device-mockup.surface.landscape {
  padding-bottom: 64.7584973%; }

.device-mockup.surface > .device,
.device-mockup.surface.landscape > .device {
  background-image: url("surface/surface.png"); }

.device-mockup.surface > .device > .screen,
.device-mockup.surface.landscape > .device > .screen {
  top: 14.5488029%;
  bottom: 14.9171271%;
  left: 9.36195587%;
  right: 9.36195587%; }

/* Samsung TV */
.device-mockup.samsung_tv,
.device-mockup.samsung_tv.landscape {
  padding-bottom: 64.68%; }

.device-mockup.samsung_tv > .device,
.device-mockup.samsung_tv.landscape > .device {
  background-image: url("samsung_tv/samsung_tv.png"); }

.device-mockup.samsung_tv > .device > .screen,
.device-mockup.samsung_tv.landscape > .device > .screen {
  top: 2.148803%;
  bottom: 12.817127%;
  left: 1.261956%;
  right: 1.361956%; }

.footerWrapper {
  background: linear-gradient(89.99deg, #E7EBF7 0.02%, #B0C6DE 74.65%, #B0C6DE 99.99%);
  color: white;
  padding: 40px 40px 0px; }

footer {
  font-family: "Lato Medium";
  padding: 0rem 0 2rem 0; }
  footer h2 {
    font-size: 21px;
    color: black;
    border-bottom: 1px dashed black;
    margin-bottom: 30px;
    padding-bottom: 10px;
    padding-top: 0; }
  footer .contactus {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 7px;
    margin: 0 auto;
    padding: 0 0 2rem 0; }
    footer .contactus a {
      border-radius: 100%;
      border: 1px solid #3E79AF;
      display: inline-block;
      width: 3rem;
      height: 3rem;
      line-height: 2.85rem;
      text-align: center;
      font-size: 1.8rem;
      text-decoration: none;
      color: #3E79AF;
      transition: all .2s; }
      footer .contactus a:hover {
        background-color: rgba(255, 255, 255, 0.4);
        color: #054D90; }
      @media (max-width: 409px) {
        footer .contactus a {
          width: 2rem;
          height: 2rem;
          line-height: 1.75rem;
          font-size: 1.2rem; } }
  footer ul {
    list-style-type: none; }
    footer ul a {
      color: black;
      text-decoration: none; }
      footer ul a:hover {
        color: #054D90; }

.footer__column {
  max-width: 220px; }

.footer__list {
  display: flex;
  gap: 0 30px;
  flex-wrap: wrap;
  justify-content: space-between; }

@media (max-width: 1040px) {
  .footer__list {
    justify-content: center; } }

@media (min-width: 800px) {
  .socials {
    padding-bottom: 3em; }
  h2 {
    padding-top: 0; }
  .left-border {
    border-left: 1px solid white;
    padding-left: 1rem; } }

@media (max-width: 767px) {
  .footerWrapper {
    padding: 30px 20px; }
  .footer__list {
    gap: 40px;
    justify-content: left; }
  .footer__list-ul li {
    width: 103px; }
  footer.contactus {
    gap: 10px 7px; }
  footer h2 {
    font-size: 15px; } }

.popupinfo {
  position: absolute;
  width: 80vmax;
  height: 80vmin; }

.cookie_notice {
  display: none;
  position: fixed;
  z-index: 9999999;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  font-size: 15px;
  font-family: "Open Sans", sans-serif;
  color: #FFF;
  background: #054D90;
  padding: 10px 20px; }

/* Оформление кнопок */
.cookie_btn {
  display: inline-block;
  margin: 10px 6px 4px 6px;
  text-decoration: none;
  position: relative;
  font-size: 13px;
  padding: 4px 12px;
  color: #FFF;
  font-weight: bold;
  text-transform: uppercase;
  background: #054D90;
  border: 2px solid #fff;
  border-radius: 5px; }

.cookie_btn:hover {
  color: #FFF; }

.cookie_btn:after {
  position: absolute;
  height: 2px;
  left: 50%;
  background: #FFF;
  bottom: -6px;
  content: "";
  transition: all 280ms ease-in-out;
  width: 0; }

.cookie_btn:hover:after {
  width: 100%;
  left: 0; }

@media (max-width: 767px) {
  .cookie_btn,
  .cookie_notice {
    font-size: 13px; } }

.posting__wrapper--auto {
  background: #F6F3FD; }

.posting__wrapper {
  padding: 40px 80px;
  text-align: center; }

.posting__title {
  font-size: 44px;
  margin-bottom: 20px; }

.posting__title-upper {
  text-transform: uppercase;
  margin: 0 0 0 10px; }

.posting__description {
  font-size: 21px;
  color: #054D90;
  margin-bottom: 50px; }

.posting__column-p {
  font-size: 21px;
  line-height: 25px; }

.posting__column-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  height: 100%; }
  .posting__column-container .posting__column-container--gif {
    position: relative; }
  .posting__column-container.posting__column-container--b24 {
    max-width: 320px;
    margin: 0 auto; }

.posting__grid {
  margin-bottom: 30px; }
  .posting__grid .posting__grid--mobile {
    display: none; }

.posting__grid-arrow {
  max-width: 50px;
  margin-bottom: 70px; }

.posting__grid-center {
  display: flex;
  align-items: center;
  justify-content: space-around;
  text-align: center;
  height: 100%; }

.posting__logo {
  margin: 30px 0; }

.posting__logo-container {
  text-align: center; }

.posting__column-gif {
  position: absolute;
  width: 75%;
  top: 11%;
  left: 13%; }

.posting__logo-text {
  font-size: 15px;
  font-weight: 700;
  color: #054D90; }

.posting__grid--mobile,
.posting__description--mobile {
  display: none; }

@media (max-width: 767px) {
  .posting__grid,
  .posting__description,
  .posting__grid--mobile {
    display: none; }
  .posting__grid.posting__grid--mobile {
    display: flex;
    padding: 0 20px; }
  .posting__description--mobile {
    display: block; }
  .posting__title-upper {
    margin: 0 0 0 5px; }
  .posting__grid-column {
    padding: 0; }
  .posting__title {
    font-size: 22px;
    padding: 0; }
  .posting__wrapper {
    padding: 30px 10px;
    text-align: center; }
  .posting__description {
    font-size: 15px;
    color: #054D90;
    margin-bottom: 34px; }
  .posting__column-container {
    justify-content: flex-start; }
  .posting__column-p {
    font-size: 13px;
    line-height: 15px; }
  .posting__column-gif {
    width: 74%;
    top: 7%;
    left: 13%; }
  .posting__logo-container {
    text-align: center;
    margin: 0 10px 100px; }
    .posting__logo-container.posting__logo-container--b24 {
      margin: 0; }
  .posting__grid-arrow.posting__grid-arrow--b24 {
    max-width: 25px; } }

.simple {
  position: relative;
  padding: 40px 80px 80px;
  background: linear-gradient(82.97deg, #B0C6DE -4.72%, #E7EBF7 33.09%, rgba(2, 181, 9, 0.31) 105.29%); }

.simple__container {
  position: relative;
  display: flex;
  justify-content: space-around;
  font-size: 21px; }

.simple__column {
  display: flex;
  justify-content: center; }

.simple__column:first-child {
  align-items: center;
  text-align: center; }

.simple__column:first-child > p {
  max-width: 170px;
  line-height: 25px; }

.simple__list {
  display: flex;
  flex-direction: column;
  gap: 13px;
  list-style: none; }

.simple__list-arrow {
  width: 26px; }
  .simple__list-arrow.simple__list-arrow--mobile {
    display: none; }

.simple__list-item {
  display: flex; }

.simple__item-img {
  margin: 0 10px; }

.simple__list-item-wrapper {
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border: 1px dashed #054D90;
  border-radius: 30px;
  width: 280px;
  height: 60px;
  margin: 0 40px 0 30px; }

.simple__column-text {
  position: relative;
  width: 200px;
  text-align: center;
  margin: auto 35px;
  line-height: 25px;
  color: #054D90;
  border: 1px dashed #054D90;
  border-radius: 30px;
  padding: 20px; }

.simple__result-text {
  text-transform: uppercase;
  font-weight: 700;
  margin: auto; }

.simple-icon {
  position: absolute;
  max-width: 80px; }
  .simple-icon.circle-vk {
    top: 20%;
    right: 40%;
    width: 77px; }
  .simple-icon.circle-ok {
    top: 70%;
    right: 35%;
    width: 65px; }
  .simple-icon.circle-in {
    top: 15%;
    left: 15%;
    width: 40px; }
  .simple-icon.circle-tumblr {
    bottom: 10%;
    left: 11%;
    width: 48px; }
  .simple-icon.circle-lj {
    left: 20%;
    top: 50%;
    width: 52px; }
  .simple-icon.circle-tw {
    top: 41%;
    left: 49%;
    width: 35px; }
  .simple-icon.circle-mail {
    right: 28%;
    width: 57px;
    top: 37%; }
  .simple-icon.circle-tg {
    right: 7%;
    top: 50%;
    width: 62px; }
  .simple-icon.circle-pint {
    top: 20%;
    right: 15%;
    width: 70px; }
  .simple-icon.circle-youtube {
    bottom: 15%;
    left: 33%; }
  .simple-icon.circle-tt {
    right: 15%;
    width: 68px;
    bottom: 42px; }

@media (max-width: 768px) {
  .simple {
    padding: 22px 10px 20px;
    background: linear-gradient(82.97deg, rgba(176, 198, 222, 0.8) -4.72%, rgba(2, 181, 9, 0.248) 105.29%); }
  .simple__container {
    flex-direction: column; }
  .simple__column {
    flex-direction: column;
    align-items: center; }
  .simple__column-text {
    border-radius: 20px;
    margin: 0 0 30px 0;
    font-size: 15px;
    width: auto; }
  .simple__column:first-child > p {
    position: relative;
    font-size: 15px;
    max-width: initial;
    height: auto;
    margin-bottom: 20px; }
  .simple__list {
    flex-direction: row;
    margin-bottom: 20px; }
  .simple__list-arrow {
    transform: rotate(90deg); }
    .simple__list-arrow.simple__list-arrow--desktop {
      opacity: 0; }
  .simple__list-item {
    flex-direction: column;
    align-items: center;
    width: auto; }
  .simple__list-item-wrapper {
    flex-direction: column;
    text-align: center;
    border-radius: 20px;
    width: auto;
    height: auto;
    margin: 30px 0;
    padding: 8px; }
  .simple__item-text {
    font-size: 13px;
    text-align: center; }
  .simple__result-text {
    margin: 30px auto 40px;
    font-size: 15px; }
  .simple-icon.circle-youtube {
    left: 5%;
    width: 33px; }
  .simple-icon.circle-tumblr {
    bottom: 8%;
    left: 15%;
    width: 37px; }
  .simple-icon.circle-tw {
    top: initial;
    bottom: 2%;
    left: 20%;
    width: 27px; }
  .simple-icon.circle-mail {
    top: initial;
    left: 28%;
    width: 40px;
    bottom: 6%; }
  .simple-icon.circle-ok {
    top: initial;
    bottom: 16%;
    left: 28%;
    width: 44px; }
  .simple-icon.circle-tt {
    left: 42%;
    width: 41px;
    bottom: 2%; }
  .simple-icon.circle-in {
    top: initial;
    bottom: 10%;
    left: 45%;
    width: 28px; }
  .simple-icon.circle-tg {
    top: initial;
    right: 29%;
    bottom: 5%;
    width: 49px; }
  .simple-icon.circle-vk {
    top: initial;
    bottom: 14%;
    right: 22%;
    width: 36px; }
  .simple-icon.circle-lj {
    top: initial;
    left: initial;
    right: 18%;
    bottom: 5%;
    width: 28px; }
  .simple-icon.circle-pint {
    top: initial;
    bottom: 11%;
    right: 7%;
    width: 35px; } }

@media (min-width: 768px) and (max-width: 1180px) {
  .simple__container {
    gap: 30px 0;
    flex-direction: column; }
  .simple__column {
    align-items: center;
    flex-direction: column; }
    .simple__column:first-child > p {
      max-width: max-content;
      margin: 0; }
  .simple__column-text {
    margin: 0 0 30px; }
  .simple__list {
    flex-direction: row; }
  .simple__list-item {
    flex-direction: column;
    align-items: center; }
  .simple__list-item-wrapper {
    flex-direction: column;
    text-align: center;
    width: auto;
    height: auto;
    margin: 30px 0;
    padding: 15px 10px; }
  .simple__list-arrow {
    transform: rotate(90deg); }
    .simple__list-arrow.simple__list-arrow--desktop {
      opacity: 0; } }

.offering {
  padding: 50px 70px;
  text-align: center;
  font-size: 21px; }

.offering__row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 56px;
  text-align: left;
  margin-bottom: 44px; }

.offering__list {
  font-size: 18px;
  list-style: none; }

.offering__column-title {
  font-size: 21px;
  color: #054D90;
  border-bottom: 1px dashed #054D90;
  padding: 0 0 15px 0;
  margin-bottom: 30px; }

.offering__list-item {
  line-height: 30px; }

.offering__subtitle {
  margin-bottom: 10px; }

.offering__subtitle--green {
  display: inline-block;
  background: #CAF6D6;
  padding: 5px;
  margin-bottom: 30px; }

.offering__footer-text {
  color: gray; }
  .offering__footer-text > a {
    color: #054D90;
    margin-left: 5px;
    text-decoration: underline; }
  .offering__footer-text > a:hover,
  .offering__footer-text > a:active,
  .offering__footer-text > a:focus {
    text-decoration: none; }

@media (max-width: 767px) {
  .offering {
    padding: 30px 15px; }
  .offering__row {
    overflow-y: scroll;
    position: relative;
    -ms-overflow-style: none;
    scrollbar-width: none;
    margin-bottom: 24px; }
    .offering__row::-webkit-scrollbar {
      display: none; }
  .offering__subtitle,
  .offering__column-title {
    font-size: 15px;
    margin-bottom: 0.5rem; }
  .offering__subtitle--green {
    margin-bottom: 40px; }
  .offering__list-item {
    line-height: 25px;
    font-size: 15px; }
  .offering__column {
    min-width: 185px; }
  .offering__footer-text {
    font-size: 13px; } }

.schedule {
  text-align: center;
  padding: 45px 60px 65px;
  background-size: contain;
  background: center/contain no-repeat url("../images/background-line.svg"), linear-gradient(89.99deg, #E7EBF7 0.02%, #B0C6DE 74.65%, #B0C6DE 99.99%); }

.schedule__column-title {
  font-size: 21px;
  color: #054D90; }

.schedule__column-text {
  max-width: 250px;
  font-size: 18px;
  line-height: 22px; }

.schedule__column {
  display: flex;
  flex-direction: column;
  align-items: center; }

.schedule__column-img {
  width: 60px;
  margin-bottom: 25px; }

.schedule__graph-wrapper {
  display: flex;
  flex-direction: column; }

.schedule__graph--mobile {
  display: none; }

@media (max-width: 767px) {
  .schedule {
    padding: 30px 20px 40px;
    background: no-repeat url("../images/background-line.svg"), linear-gradient(89.99deg, #E7EBF7 0.02%, #B0C6DE 74.65%, #B0C6DE 99.99%); }
  .schedule__row {
    overflow-y: scroll;
    scrollbar-width: none;
    flex-wrap: nowrap; }
    .schedule__row::-webkit-scrollbar {
      display: none; }
  .schedule__column-title {
    font-size: 15px;
    margin: 0; }
  .schedule__column-text {
    font-size: 13px;
    line-height: 18px; }
  .schedule__column-img {
    margin-bottom: 5px; }
  .schedule__graph {
    display: none; }
  .schedule__graph--mobile {
    display: inline-block;
    width: 100%; } }

.analytics {
  padding: 45px 60px;
  text-align: center; }

.analytics__list {
  list-style: none;
  display: flex;
  justify-content: space-between;
  margin: 40px 0 30px; }

.analytics__list-item {
  position: relative;
  padding-left: 35px; }
  .analytics__list-item.analytics__list-item--posts:before {
    background: #3BBFE1; }
  .analytics__list-item.analytics__list-item--likes:before {
    background: #FFD900; }
  .analytics__list-item.analytics__list-item--reposts:before {
    background: #7EBE6D; }
  .analytics__list-item.analytics__list-item--comments:before {
    background: #EE9CE5; }
  .analytics__list-item.analytics__list-item--mistakes:before {
    background: #FF8282; }

.analytics__list-item::before {
  position: absolute;
  content: '';
  width: 18px;
  height: 18px;
  background: gray;
  left: 0;
  top: 2px; }

.analytics__hidden-text {
  position: relative;
  display: inline-block;
  color: #707B91;
  margin: 30px auto 50px;
  width: fit-content; }

.analytics__hidden-text::before {
  position: absolute;
  content: "";
  width: 27px;
  height: 27px;
  background: no-repeat url("../images/exclamation-icon.svg");
  top: -5px;
  left: -50px; }

.analytics__column-text {
  padding: 0 60px 0 0;
  line-height: 25px; }

@media (max-width: 767px) {
  .analytics {
    padding: 30px 20px; }
  .analytics__list {
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 15px; }
  .analytics__hidden-text {
    padding-left: 30px; }
    .analytics__hidden-text::before {
      left: 0;
      top: 0;
      width: 18px;
      height: 18px;
      padding-left: 30px;
      background-size: 18px auto; }
  .analytics__column-text {
    font-size: 13px;
    line-height: 15px;
    padding: 0; }
  .offering__row--analytics.offering__column {
    min-width: 200px; } }

@media (min-width: 1700px) {
  .analytics__hidden-text::before {
    top: 0; } }

.above-all {
  padding: 50px 40px; }

.above-all__row {
  display: flex;
  justify-content: space-around;
  gap: 40px; }

.above-all__column {
  text-align: center;
  max-width: 280px; }

.above-all__cell {
  max-width: 320px;
  text-align: center; }

.above-all__text {
  font-size: 18px;
  line-height: 22px; }
  .above-all__text.blue {
    color: #054D90; }
  .above-all__text.green {
    color: #02B509;
    font-size: 42px; }
  .above-all__text.small {
    font-size: 22px; }

.above-all__img--center {
  margin-bottom: 3rem;
  transition: all .2s ease-in-out;
  -webkit-transition: all .2s ease-in-out;
  -moz-transition: all .2s ease-in-out;
  -ms-transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out; }
  .above-all__img--center:hover {
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1); }

@media (max-width: 767px) {
  .above-all {
    padding: 50px 10px; }
    .above-all .section__title {
      margin-bottom: 30px; }
  .above-all__row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 10px 10px; }
    .above-all__row.above-all__row--grid {
      grid-template-rows: 300px 1fr; }
  .above-all__text {
    font-size: 13px;
    line-height: 15px; }
  .above-all__img {
    width: 36px; }
    .above-all__img.above-all__img--center {
      width: 121px;
      margin-bottom: 0; }
  .above-all__cell.above-all__cell--center {
    grid-column-start: 1;
    grid-column-end: -1;
    grid-row-start: 1;
    grid-row-end: 2;
    margin: 0 auto; }
    .above-all__cell.above-all__cell--center div {
      display: flex;
      flex-direction: column;
      align-items: center; } }

body {
  overflow-x: hidden;
  position: relative;
  max-width: 100vw; }

.notAbsoluteInFlex {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0; }

.ie {
  width: 12vmin;
  height: 12vmin; }
  .ie #bubbles {
    display: none; }

.divider {
  height: 100px;
  background: fixed url("../images/mobile.jpg");
  background-size: cover; }
  @media (min-width: 800px) {
    .divider {
      background: fixed url("../images/desktop.jpg"); } }
  .divider:after {
    content: "";
    display: block;
    height: 100%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.65) 0%, transparent 33%, transparent 69%, rgba(0, 0, 0, 0.65) 100%); }

.sectionWrapper {
  background: white; }

#secondSection, #thirdSection, #fourthSection, #fifthSection, #sixSection, #lastSection, footer {
  width: 100%;
  margin: 0 auto; }

#lastSection, #thirdSection {
  background: #F2F2F2; }

#lastSection {
  background: #DEDFE1;
  padding-top: 50px;
  padding-bottom: 50px; }
  #lastSection .action__btn_white {
    display: inline-block;
    max-width: 90%;
    background: linear-gradient(to top, #25064a, #005cdb);
    padding: .7em 2.2em;
    border-radius: 30px;
    text-decoration: none;
    color: white;
    transition: all .2s;
    text-transform: uppercase; }
    #lastSection .action__btn_white:hover {
      box-shadow: 0 0 10px #0094FF; }

.action {
  text-align: center;
  padding: 2rem 0; }
  .action .action__btn {
    background: linear-gradient(to top, #25064A, #005CDB);
    font-size: 1.1rem;
    display: inline-block;
    max-width: 90%;
    padding: .7em 2.2em;
    border-radius: 30px;
    text-decoration: none;
    color: white;
    transition: all .2s;
    margin-bottom: 5px; }
    .action .action__btn:hover {
      background: linear-gradient(to top, #25064A, #005CDB);
      box-shadow: 0 0 10px #0094FF; }

@media (max-width: 1199px) {
  .margin-row-null {
    margin-left: 0px;
    margin-right: 0px; } }

#thirdSection .device-container {
  padding: 0 10%;
  margin: 0 auto;
  margin-bottom: 24px; }

.full-hd-img {
  width: 100%; }

.arroy {
  position: absolute;
  height: 0;
  width: 0;
  border: 2vw solid transparent;
  border-top: 2vw solid #2f7ff6;
  left: -2vw;
  z-index: 1;
  bottom: -4vw; }
  @media (max-width: 767px) {
    .arroy {
      border: 5vw solid transparent;
      border-top: 5vw solid #ffffff;
      left: 46vw;
      z-index: 1;
      bottom: -10.3vw; } }

.auto-parts-1 {
  width: 5vw;
  bottom: -4vw; }
  @media (max-width: 767px) {
    .auto-parts-1 {
      width: 14vw; } }

.pict-schema {
  padding-bottom: 7vw; }
  .pict-schema picture {
    vertical-align: top; }

.auto-parts-2 {
  width: 9vw; }
  @media (max-width: 767px) {
    .auto-parts-2 {
      width: 24vw; } }

#start .how-it-works, #secondSection .how-it-works {
  position: relative;
  height: 100%;
  border: 1px solid lightgrey; }
  #start .how-it-works h3, #secondSection .how-it-works h3 {
    align-items: center;
    display: flex;
    font-size: 1.8vw;
    font-weight: 300; }
    @media (max-width: 767px) {
      #start .how-it-works h3, #secondSection .how-it-works h3 {
        font-size: 5vw;
        font-weight: 400; } }
  #start .how-it-works img, #secondSection .how-it-works img {
    max-width: 100%;
    vertical-align: middle; }
  #start .how-it-works .helper, #secondSection .how-it-works .helper {
    display: inline-block;
    height: 100%;
    vertical-align: middle; }

.price-container {
  text-align: center;
  background: white;
  border: 1px solid #BBBBBB;
  border-radius: 20px; }
  .price-container .price-header {
    margin-bottom: 2rem;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 20%, rgba(255, 255, 255, 0.6) 50%, rgba(255, 255, 255, 0) 75%), linear-gradient(to left, #000529, #061267, #0018BF, #00F0FF, #0018BF, #061267, #000529);
    margin-right: -15px;
    margin-left: -15px;
    border-radius: 20px 20px 0px 0px; }
    .price-container .price-header h1 {
      color: #000000;
      font-weight: 600;
      font-size: 2rem;
      padding-top: 0.72em;
      padding-bottom: 0.72em;
      text-shadow: white 0px 0px 2px, white 0px 0px 2px, white 0px 0px 2px, white 0px 0px 2px, white 0px 0px 2px, white 0px 0px 10px, white 0px 0px 10px; }
      .price-container .price-header h1:after {
        content: "";
        display: block;
        background: #0075FF;
        width: 100px;
        max-width: 30%;
        height: 2px;
        margin: 0.25em auto 0; }
  .price-container .to-globus {
    padding: 0px;
    align-items: center;
    display: flex; }
    .price-container .to-globus .project-title {
      font-weight: bold;
      font-size: 1.7vw; }
      @media (max-width: 767px) {
        .price-container .to-globus .project-title {
          font-size: 5vw; } }
  .price-container .globus-price {
    display: flex;
    align-items: center;
    height: 16vw;
    background: url("/templates/seolit-mdl-index/assets/images/globus-price.png") no-repeat center center;
    background-size: 80% auto; }
    @media (max-width: 767px) {
      .price-container .globus-price {
        height: 41vw; } }
    .price-container .globus-price .cost-project {
      font-family: "Lato Medium";
      font-size: 3.5vw;
      margin-top: 0.5vw;
      font-weight: 700;
      color: #003FB9;
      text-shadow: #fff 0px 0px 5px, #fff 0px 0px 5px, #fff 0px 0px 5px, #fff 0px 0px 10px, #fff 0px 0px 10px, #fff 0px 0px 10px; }
      @media (max-width: 767px) {
        .price-container .globus-price .cost-project {
          font-size: 9.5vw; } }
      .price-container .globus-price .cost-project::after {
        margin-left: 5px;
        font-family: 'icomoon';
        font-size: 2.8vw;
        content: '\f158'; }
        @media (max-width: 767px) {
          .price-container .globus-price .cost-project::after {
            font-size: 7.8vw; } }
  .price-container .icon-circle {
    margin-bottom: 1.0rem;
    justify-content: center;
    display: flex;
    flex-direction: row;
    align-items: center; }
    .price-container .icon-circle::before {
      display: block;
      margin-right: 10px;
      content: '';
      height: 1px;
      width: 32px;
      background-color: #0094FF; }
      @media (max-width: 767px) {
        .price-container .icon-circle::before {
          width: 25px; } }
    .price-container .icon-circle::after {
      display: block;
      margin-left: 10px;
      content: '';
      height: 1px;
      width: 32px;
      background-color: #0094FF; }
      @media (max-width: 767px) {
        .price-container .icon-circle::after {
          width: 25px; } }
  .price-container .icon-price {
    border-radius: 100%;
    border: 2px solid #97EAFF;
    box-shadow: 0 0 7px 2px #0094FF;
    display: inline-block;
    width: 3rem;
    height: 3rem;
    line-height: 2.85rem;
    text-align: center;
    font-size: 1.6rem;
    background: radial-gradient(circle, rgba(16, 0, 201, 0.4) -20%, #00266f 75%, #00153d 100%);
    text-shadow: #0094FF 0px 0px 5px, #0094FF 0px 0px 5px;
    text-decoration: none;
    color: #9AEEFF;
    transition: all .2s; }
    @media (min-width: 1600px) {
      .price-container .icon-price {
        width: 3vw;
        height: 3vw;
        font-size: 1.6vw;
        line-height: 2.8vw; } }
    .price-container .icon-price.ss:before {
      content: '\f110'; }
    .price-container .icon-price.Rss:before {
      content: '\f09e'; }
    .price-container .icon-price.delegir:before {
      content: '\e906'; }
    .price-container .icon-price.schedule:before {
      content: '\e907'; }
    .price-container .icon-price.watermark:before {
      content: '\e908'; }
    .price-container .icon-price.filtr:before {
      content: '\e905'; }
    .price-container .icon-price.template:before {
      content: '\e909'; }
    .price-container .icon-price.trash:before {
      content: '\e90a'; }
  .price-container .dop-account-cost {
    font-family: 'Lato Medium';
    font-weight: bold; }
    @media (min-width: 768px) {
      .price-container .dop-account-cost {
        font-size: 1rem; } }
    .price-container .dop-account-cost span {
      font-size: 2vw;
      color: #003fb9;
      text-shadow: #fff 0 0 5px, #fff 0 0 5px, #fff 0 0 5px, #fff 0 0 10px, #fff 0 0 10px, #fff 0 0 10px; }
      @media (max-width: 767px) {
        .price-container .dop-account-cost span {
          font-size: 24px; } }
  @media (min-width: 1600px) {
    .price-container .bottom-height-FullHD {
      height: 8vw; } }

.green-button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 220px;
  min-width: 220px;
  height: 50px;
  border: none;
  border-radius: 5px;
  background: #02B509;
  text-transform: uppercase;
  font-size: 21px;
  font-weight: 700;
  color: white;
  transition: all 0.1s linear 0s; }
  .green-button:hover, .green-button:focus {
    color: white;
    text-decoration: none;
    background: #019807; }
  .green-button:active {
    outline: none;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.5) inset;
    background: #018D06;
    color: gray; }

.section__title {
  font-size: 44px;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 40px;
  padding-top: 0; }

.b24__wrapper {
  background: linear-gradient(180deg, #F1FEFE 0%, #B9E5F1 78.65%); }

.b24__text {
  font-size: 18px;
  line-height: 22px;
  max-width: 800px;
  margin: 30px auto 50px; }
  .b24__text > b {
    margin: 0 5px; }

@media (max-width: 767px) {
  .green-button {
    width: 50vw;
    min-width: 50vw;
    height: 30px;
    font-size: 15px;
    margin: 0 auto; }
  .section__title {
    font-size: 22px;
    padding: 0;
    margin-bottom: 0; }
  .b24__text {
    font-size: 13px;
    line-height: 15px; } }
