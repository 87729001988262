$animationDuration: 2s;
$bubbleSize: 7vmin;
$lineWidth:30vmin;
$logoSize:12vmin;

%logo-same{
  font-size: $logoSize;
  position: absolute;
  line-height: $logoSize;
  color:white;
  text-shadow: -2px 2px 2px rgba(0,0,0,0.8);
}

video {
  filter:blur(2px);
  position: fixed;
  top: 50%;
  left: 50%;
  min-width: 101%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -100;
  transform: translateX(-50%) translateY(-50%);
  background: url('../images/cover.jpg') no-repeat;
  background-size: cover;
  transition: 1s opacity;
}

.logoDescription{
  position: absolute;
  width: 100%;
  top: 75vh;
  color: white;
  text-shadow: -2px 2px 2px rgba(0,0,0,0.8);
  text-align: center;
  z-index: 1;
  @media (min-width: 600px) {
    font-size: 3.75vmin;
    top: 55vh;
    width: 30vw;
    left: 34.5vw;
  }
}

.scroll{
  color: rgba(255,255,255,0.8);
  position: absolute;
  display: block;
  margin:0 auto;
  top: 90vh;
  font-size: 3rem;
  animation: scroll;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  width: 100%;
  text-align: center;
}

#firstSection{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width:100%;
  height:100vh;
  background: radial-gradient(circle closest-side at center, rgba(30,87,153,1) 0%,rgba(161,181,203,0) 80%,rgba(229,229,229,0) 100%);
}
#bubbles-holder {
  position: relative;

  #bubbles {
    width: $logoSize;
    height: $logoSize;
    overflow: visible;
    //text-indent: -30vmin;
    position: relative;
    padding-top: $logoSize/2;
  }

  //#logo {
  //  position: absolute;
  //  width: 100%;
  //  height: 100%;
  //  top:0;
  //  left: 0;
  //  animation-name: pulse;
  //  animation-delay: $animationDuration;
  //  animation-duration: $animationDuration;
  //  animation-iteration-count: infinite;
  //  animation-timing-function: linear;
  //  //font-family: Arial;
  //
  //
  //  &:before{
  //    content: "SE";
  //    left:-105%;
  //    @extend %logo-same;
  //  }
  //  &:after{
  //    @extend %logo-same;
  //    content: "LIT";
  //    right:-130%;
  //  }
  //}
}

.bubbles_line{
  width:30vmin;
  height: 2px;
  position:absolute;
  right: 50%;
  background: linear-gradient(to right, rgba(255,255,255,0.3) 0%,rgba(255,255,255,0.3) 40%,white 50%,rgba(255,255,255,0.3) 60%,rgba(255,255,255,0.3) 100%);
  background-size: 240% 100%;
  transform-origin: $lineWidth+$logoSize/2.15 center;
  margin-right: $logoSize/2.15;

  &::before{
    position: absolute;
    left: -($bubbleSize/2);
    bottom: -($bubbleSize/2);
    display: block;
    width: $bubbleSize;
    height: $bubbleSize;
    border-radius: 100%;
    line-height: $bubbleSize;
    text-align: center;
    font-family: icomoon;
    font-size: 0.6 * $bubbleSize;
    color:$white;
    border:2px solid $white;
    transition-property: border-width;
    transition-duration: 100ms;
  }
  &:hover::before{
    border-width:3px;
    transition-property: border-width;
    transition-duration: 100ms;
  }


  //&:after{
  //  content: "";
  //  position:absolute;
  //  //top:-50%;
  //  width:100%;
  //  height:100%;
  //  background: linear-gradient(to right, rgba(255,255,255,0.3) 0%,rgba(255,255,255,0.3) 40%,white 50%,rgba(255,255,255,0.3) 60%,rgba(255,255,255,0.3) 100%);
  //  background-size: 240% 100%;
  //}

  &.in{
    animation-name: in;
    animation-duration: $animationDuration;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }


  &.out{
    animation-name: out;
    animation-delay: $animationDuration;
    animation-duration: $animationDuration;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
}

@media (max-aspect-ratio:8/10){
  .bubbles_line{
    width:1.3*$lineWidth;
    transform-origin:1.3*$lineWidth+$bubbleSize/1.3 center;
    margin-right: $bubbleSize/1.3;
    &:before{
      width: 1.2 * $bubbleSize;
      height: 1.2 * $bubbleSize;
      line-height: 1.2 * $bubbleSize;
      bottom: -1.2*($bubbleSize/2);
      left: -1.2*($bubbleSize/2);
    }
  }
}

@keyframes in {
  from{background-position:100% 50%}
  to{background-position:0% 50%}
}
@keyframes out {
  from {background-position:0% 50%}
  to {background-position:100% 50%}
}
//@keyframes pulse {
//  0%{}
//  80%{filter:brightness(100%)}
//  90%{
//    filter:brightness(200%);
//  }
//  100%{filter:brightness(100%)}
//}
@keyframes scroll {
  0%{top: 90vh;}
  80%{top: 90vh;}
  90%{top: 92vh;}
  100%{top: 90vh;}
}
