.footerWrapper{
  background: $footerBG;
  color: $white;
  padding: 40px 40px 0px;
}

footer{
  font-family: "Lato Medium";
  padding: 0rem 0 2rem 0;
  h2{
    font-size: 21px;
    color: black;
    border-bottom: 1px dashed black;
    margin-bottom: 30px;
    padding-bottom: 10px;
    padding-top: 0;
  }
  .contactus{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 7px;
    margin: 0 auto;
    //max-width: 700px;
    padding: 0 0 2rem 0;
      a{
        border-radius: 100%;
        border:1px solid #3E79AF;
        display:inline-block;
        width:3rem;
        height:3rem;
        line-height: 2.85rem;
        text-align: center;
        font-size: 1.8rem;
        text-decoration: none;
        color: #3E79AF;
        transition: all .2s;
        &:hover{
          background-color: rgba(255, 255, 255, 0.4);
          color: $blue;
        }
        @media(max-width: 409px){
          width: 2rem;
          height: 2rem;
          line-height: 1.75rem;
          font-size: 1.2rem;
        }
      }
  }

  ul {
    list-style-type: none;
    a{
      color: black;
      text-decoration: none;
      &:hover{
        color: $blue;
      }
    }
  }
}

.footer__column {
  max-width: 220px;
}

.footer__list {
  display: flex;
  gap: 0 30px;
  flex-wrap: wrap;
  justify-content: space-between;
}

@media (max-width: 1040px) {
  .footer__list {
    justify-content: center;
  }
}

@media (min-width: 800px) {
  .socials{
    padding-bottom: 3em;
  }
  h2{
    padding-top:0;
  }
  .left-border{
    border-left:1px solid $white;
    padding-left: 1rem;
  }
}

@media (max-width: 767px) {
  .footerWrapper {
    padding: 30px 20px;
  }
  .footer__list {
    gap: 40px;
    justify-content: left;
  }
  .footer__list-ul li {
    width: 103px;
  }
  footer {
    &.contactus {
      gap: 10px 7px;
    }
    & h2 {
      font-size: 15px;
    }
  }
}
