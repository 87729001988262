.offering {
  padding: 50px 70px;
  text-align: center;
  font-size: 21px;
}
.offering__row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 56px;
  text-align: left;
  margin-bottom: 44px;
}
.offering__list {
  font-size: 18px;
  list-style: none;
}
.offering__column-title {
  font-size: 21px;
  color: $blue;
  border-bottom: 1px dashed $blue;
  padding: 0 0 15px 0;
  margin-bottom: 30px;
}
.offering__list-item {
  line-height: 30px;
}
.offering__subtitle {
  margin-bottom: 10px;
}
.offering__subtitle--green {
  display: inline-block;
  background: #CAF6D6;
  padding: 5px;
  margin-bottom: 30px;
}
.offering__footer-text {
  color: gray;

  & > a {
    color: $blue;
    margin-left: 5px;
    text-decoration: underline;
  }

  & > a:hover,
  & > a:active,
  & > a:focus, {
    text-decoration: none;
  }
}

@media (max-width: 767px) {
  .offering {
    padding: 30px 15px;
  }
  .offering__row {
    overflow-y: scroll;
    position: relative;
    -ms-overflow-style: none;
    scrollbar-width: none;
    margin-bottom: 24px;

    &::-webkit-scrollbar {
      display: none;
    }
  }
  .offering__subtitle,
  .offering__column-title{
    font-size: 15px;
    margin-bottom: 0.5rem;
  }
  .offering__subtitle--green {
    margin-bottom: 40px;
  }
  .offering__list-item {
    line-height: 25px;
    font-size: 15px;
  }
  .offering__column {
    min-width: 185px;
  }
  .offering__footer-text {
    font-size: 13px;
  }
}