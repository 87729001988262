.posting__wrapper--auto {
  background: #F6F3FD;
}
.posting__wrapper {
  padding: 40px 80px;
  text-align: center;
}
.posting__title {
  font-size: 44px;
  margin-bottom: 20px;
}
.posting__title-upper {
  text-transform: uppercase;
  margin: 0 0 0 10px;
}
.posting__description {
  font-size: 21px;
  color: $blue;
  margin-bottom: 50px;
}
.posting__column-p {
  font-size: 21px;
  line-height: 25px;
}
.posting__column-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  height: 100%;

  & .posting__column-container--gif {
    position: relative;
  }

  &.posting__column-container--b24 {
    max-width: 320px;
    margin: 0 auto;
  }
}
.posting__grid {
  margin-bottom: 30px;

  .posting__grid--mobile {
    display: none;
  }
}
.posting__grid-arrow {
  max-width: 50px;
  margin-bottom: 70px;
}
.posting__grid-center {
  display: flex;
  align-items: center;
  justify-content: space-around;
  text-align: center;
  height: 100%;
}
.posting__logo {
  margin: 30px 0;
}
.posting__logo-container {
  text-align: center;
}
.posting__column-gif {
  position: absolute;
  width: 75%;
  top: 11%;
  left: 13%;
}
.posting__logo-text {
  font-size: 15px;
  font-weight: 700;
  color: $blue;
}
.posting__grid--mobile,
.posting__description--mobile {
  display: none;
}

@media (max-width: 767px) {
  .posting__grid,
  .posting__description,
  .posting__grid--mobile {
    display: none;
  }
  .posting__grid.posting__grid--mobile {
    display: flex;
    padding: 0 20px;
  }
  .posting__description--mobile {
    display:block;
  }

  .posting__title-upper {
    margin: 0 0 0 5px;
  }
  .posting__grid-column {
    padding: 0;
  }
  .posting__title {
    font-size: 22px;
    padding: 0;
  }
  .posting__wrapper {
    padding: 30px 10px;
    text-align: center;
  }
  .posting__description {
    font-size: 15px;
    color: #054D90;
    margin-bottom: 34px;
  }
  .posting__column-container {
    justify-content: flex-start;
  }
  .posting__column-p {
    font-size: 13px;
    line-height: 15px;
  }
  .posting__column-gif {
    width: 74%;
    top: 7%;
    left: 13%;
  }
  .posting__logo-container {
    text-align: center;
    margin: 0 10px 100px;

    &.posting__logo-container--b24 {
      margin: 0;
    }
  }
  .posting__grid-arrow {
    &.posting__grid-arrow--b24 {
      max-width: 25px;
    }
  }
}